import { Box, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getManyCompaniesById } from "../../../lib/usersBEClient";
import { UserContext } from "../../../context/userContext";
import { ConsultiveGroupScreen } from "../../../screens/Mi Lecosy/Group/ConsultiveGroupScreen";
import { ConsultiveGroupInfo } from "../ConsultiveGroup/ConsultiveGroupInfo";
import { BeneficiaryGroupScreen } from "../../../screens/Mi Lecosy/Group/BeneficiaryGroupScreen";
import { GroupServiceCompaniesTable } from "../ConsultiveGroup/GroupServiceCompaniesTable";
import { GroupServiceUsers } from "../ConsultiveGroup/GroupServiceUsers";

const CustomTabPanel = ({ value, children }) => (
   <Box role="tabpanel" hidden={!children}>
      {children}
   </Box>
);

const services = [
   {
      id: "64e784dd978b71bd4f011ee3",
      service: "Canal de denuncias",
      component: <GroupServiceUsers serviceCode={"CD"} disableBorders />,
      subcomponent: <GroupServiceCompaniesTable serviceId={"64e784dd978b71bd4f011ee3"} />,
   },
   {
      id: "64e7851d978b71bd4f011ee4",
      service: "Gobierno corporativo",
      component: <ConsultiveGroupInfo />,
      subcomponent: <ConsultiveGroupScreen />,
   },
   {
      id: "66d75b0724169eb3399c631b",
      service: "Beneficiario controlador",
      component: <ConsultiveGroupInfo />,
      subcomponent: <BeneficiaryGroupScreen />,
   },
];

const GroupServicesScreen = () => {
   const { user } = useContext(UserContext);
   const [availableServices, setAvailableServices] = useState([]);
   const [value, setValue] = useState(services[0]?.id || "");
   const [isLoading, setIsLoading] = useState(true);

   const fetchCompanies = useCallback(async () => {
      if (!user?.group) return;
      setIsLoading(true);
      const companiesResponse = await getManyCompaniesById(user.group.companies);
      const available = services.filter((service) =>
         companiesResponse?.some((company) =>
            company.company_details.servicesDetails.some((s) => s.serviceId === service.id && !s.disable)
         )
      );
      setAvailableServices(available);
      setValue(available[0]?.id || "");
      setIsLoading(false);
   }, [user?.group]);

   useEffect(() => {
      fetchCompanies();
   }, [fetchCompanies]);

   const handleChange = (_event: React.SyntheticEvent, newValue: string) => setValue(newValue);
   const selectedService = useMemo(
      () => availableServices.find((service) => service.id === value) || availableServices[0] || {},
      [value, availableServices]
   );

   return (
      <Stack direction="column" spacing={2}>
         <Stack sx={{ borderRadius: 1, bgcolor: "white", boxShadow: 2 }}>
            {isLoading ? (
               <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", py: 2 }}>
                  <CircularProgress />
               </Box>
            ) : (
               <>
                  <Box display="flex" sx={{ borderBottom: 1, borderColor: "divider" }}>
                     <Tabs sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} value={value} onChange={handleChange}>
                        {availableServices.map((tab) => (
                           <Tab
                              key={tab.id}
                              value={tab.id}
                              label={tab.service}
                              sx={{ fontSize: 14, fontWeight: 600 }}
                           />
                        ))}
                     </Tabs>
                  </Box>
                  <CustomTabPanel value={value}>{selectedService?.component}</CustomTabPanel>
               </>
            )}
         </Stack>
         {selectedService?.subcomponent}
      </Stack>
   );
};

export default GroupServicesScreen;
