import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { UserContext } from "../context/userContext";
import { useNavigate, Link } from "react-router-dom";
import { LoadingButton } from "../components/LoadingButton";
import {
   Button,
   CircularProgress,
   CssBaseline,
   Divider,
   Fab,
   ListItemText,
   MenuItem,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { AxiosError } from "axios";
import { errorDataType } from "../types/BaseTypes";
import { InputBaseMod } from "../components/Inputs/InputBase";
import { Form, Formik } from "formik";
import { securityQuestionSchema, userLoginSchema } from "../lib/validations/inputSchemas";
import { SnackBarContext } from "../context/snackBarContext";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { SupportContext } from "../context/supportContext";
import { logo_Lecosy } from "../assets/icons/DrawerAppScreenIcons";
import { SystemThemeConfig } from "../config/systemTheme.config";
import { LoginUser, updateUserDetailsByUserId } from "../lib/usersBEClient";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputSelectField } from "../components/Inputs/InputSelectField";
import { InputTextField } from "../components/Inputs/InputTextField";
import { getGoverningBodyNewCharges } from "../lib/gobCorpBEClient";

export const LoginScreenNew = () => {
   const { setLoggedIn, setIsAuthenticated, getPendingCharges } = useContext(UserContext);
   const { setSupportOpen } = useContext(SupportContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [showPassword, setShowPassword] = useState(false);
   const [securityQuestionScreen, setSecurityQuestionScreen] = useState(false);
   const [userId, setUserId] = useState(null);
   const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false);
   const { mutate, isLoading } = LoginUser();
   const navigate = useNavigate();
   const questionArray = [
      "¿Cuál es su comida favorita?",
      "¿Cuál era su apodo en la infancia?",
      "¿En qué ciudad se conocieron sus padres?",
      "¿Cuál era el nombre de su primera mascota?",
      "¿En qué ciudad nació su padre?",
      "¿En qué ciudad nació su madre?",
      "¿Cuál es el apellido de su profesor favorito del instituto?",
      "Cuando era joven, ¿Qué quería ser de mayor?",
      "¿Cuál es el segundo apellido de su madre?",
      "¿En qué ciudad conoció a su cónyuge o pareja?",
      "¿Cuál era el nombre y apellido de su mejor amigo de la infancia?",
      "¿Cómo se llamaba la primera escuela a la que asistió?",
      "¿Cuál era su deporte favorito en el instituto?",
      "¿En qué ciudad vive su hermano más próximo?",
   ];

   const login = async (values) => {
      try {
         mutate(
            { email: values.email, password: values.password },
            {
               onError: (error: any) => {
                  if (error.response.data.message === "email not verified")
                     showSnackBar("Correo electrónico no verificado", true);
                  else if (error.response.data.message === "invalid credentials")
                     showSnackBar("Usuario o contraseña incorrectos", true);
                  else if (error.response.data.message === "something went wrong")
                     showSnackBar("Error, inténtelo de nuevo más tarde", true);
                  else if (error.response.data.message === "the contract is disabled")
                     showSnackBar("Su cuenta fue dada de baja", true);
                  else if (error.response.data.message === "Disabled company")
                     showSnackBar("Su compañía se encuentra inhabilitada", true);
                  else if (error.response.data.message === "Error, try again")
                     showSnackBar("Usuario y/o contraseña incorrectos", true);
                  else if (error.response.data.message === "user account is disable")
                     showSnackBar("Cuenta inhabilitada", true);
                  else if (error.response.data.message === "Session in use") showSnackBar("Sesión en uso", true);
                  else showSnackBar("Error, intente de nuevo", true);
                  return setIsLoadingUserDetails(false);
               },
               onSuccess: (data) => {
                  setUserId(data._id);
                  if (!data.alreadyHasLogin) return setSecurityQuestionScreen(true);
                  if (!data.validPwd) navigate("/validar-contrasena");
                  else {
                     setLoggedIn(true);
                     setIsAuthenticated(true);
                     navigate("/inicio");
                  }
               },
               // onSuccess: async (data) => {
               //    const pendingCharges = await getPendingCharges(data._id);
               //    if (pendingCharges.length > 0) {
               //       setSecurityQuestionScreen(false);
               //       setLoggedIn(true);
               //       setIsAuthenticated(true);
               //       navigate("/charges");
               //    } else {
               //       setUserId(data._id);
               //       if (data.alreadyHasLogin && data.validPwd) {
               //          setSecurityQuestionScreen(false);
               //          setLoggedIn(true);
               //          setIsAuthenticated(true);
               //          navigate("/inicio");
               //       }
               //       if (!data.validPwd) navigate("/validar-contrasena");
               //       else {
               //          setSecurityQuestionScreen(true);
               //       }
               //    }
               // },
            }
         );
      } catch (error) {
         const errorResponse: AxiosError = error as AxiosError;
         const errorMessage: errorDataType = errorResponse.response?.data as errorDataType;
         if (errorMessage.message === "the contract is disabled") {
            showSnackBar("Su cuenta fue dada de baja", true);
            return;
         }
         if (errorMessage.message === "the user is disabled") {
            showSnackBar("Su usuario fue dada de baja", true);
            return;
         }
         if (errorMessage.message === "email not verified") {
            showSnackBar("Correo electrónico no verificado", true);
            return;
         }
         showSnackBar("Error, intente de nuevo", true);
         setIsLoadingUserDetails(false);
      }
   };

   const handleSecurityQuestion = async (values) => {
      const userDetailsData = await updateUserDetailsByUserId({
         _id: userId,
         updateData: {
            securityQuestion: values.securityQuestion,
            securityAnswer: values.securityAnswer,
            alreadyHasLogin: true,
         },
      });
      if (userDetailsData.data) {
         window.location.reload();
      }
   };

   return (
      <SystemThemeConfig>
         <CssBaseline />
         <div>
            <Box display={"flex"} justifyContent="center" height="100vh" bgcolor={"#F5F5F5"}>
               <Container
                  maxWidth="xl"
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flexWrap: "wrap",
                  }}
               >
                  <Box
                     flex={1}
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: { md: "center", xs: "flex-end" },
                        pb: { xs: 5, md: 0 },
                     }}
                  >
                     <Box
                        component={"img"}
                        sx={{ maxHeight: { md: 300, xs: 120 }, maxWidth: { md: 400, xs: 280 } }}
                        alt="Logo Lecosy"
                        src={logo_Lecosy}
                     />
                  </Box>
                  <Box
                     flex={1}
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: { md: "center", xs: "none" },
                     }}
                  >
                     {securityQuestionScreen ? (
                        <Box sx={{ bgcolor: "white", boxShadow: 1, borderRadius: 6, px: 4, py: 1, maxHeight: 500 }}>
                           <Formik
                              initialValues={{ securityQuestion: "" }}
                              validationSchema={securityQuestionSchema}
                              onSubmit={(values) => {
                                 handleSecurityQuestion(values);
                              }}
                           >
                              <Form>
                                 <Typography
                                    variant="h5"
                                    textAlign={"center"}
                                    sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}
                                 >
                                    Pregunta de seguridad
                                 </Typography>
                                 <Divider color="black" variant="middle" sx={{ my: 1 }} />
                                 <Typography textAlign={"center"}>
                                    Es necesario seleccionar una pregunta de seguridad para continuar
                                 </Typography>
                                 <InputSelectField
                                    label={"Opciones de preguntas de seguridad"}
                                    labelId={"securityQuestionLabel"}
                                    id={"securityQuestion"}
                                    name={"securityQuestion"}
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    renderValue={(selected) => (
                                       <Box sx={{ flexWrap: "wrap", gap: 0.5, display: "flex" }}>
                                          <Typography>{selected}</Typography>
                                       </Box>
                                    )}
                                 >
                                    {questionArray.map((question, index) => (
                                       <MenuItem key={index} value={question}>
                                          <ListItemText primary={question} />
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                                 <Typography sx={{ color: "#7D8592", mt: 2 }}>Respuesta:</Typography>
                                 <Divider color="#EBEBEB" variant="fullWidth" sx={{ my: 1 }} />
                                 <InputTextField
                                    id={"securityAnswer"}
                                    name={"securityAnswer"}
                                    type={"text"}
                                    placeholder="Descripción"
                                    fullWidth
                                    multiline
                                    rows={3}
                                 />
                                 <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 1 }}>
                                    <Button variant="contained" type="submit">
                                       {!isLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                                    </Button>
                                 </Box>
                              </Form>
                           </Formik>
                        </Box>
                     ) : (
                        <Box sx={{ bgcolor: "white", boxShadow: 1, borderRadius: 6, px: 4, py: 1, maxHeight: 380 }}>
                           <Typography variant="h6" sx={{ m: 2 }} textAlign="center" color={"#142c44"}>
                              Accede a tu cuenta
                           </Typography>
                           <Divider sx={{ bgcolor: "#162c44" }} />
                           <Formik
                              initialValues={{
                                 email: "",
                                 password: "",
                              }}
                              validationSchema={userLoginSchema}
                              onSubmit={login}
                           >
                              <Form>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       mx: 2,
                                       mt: 4,
                                       minWidth: { md: 350, xs: 190 },
                                       alignItems: "center",
                                    }}
                                 >
                                    <Box sx={{ display: "flex", minHeight: 50, minWidth: "100%" }}>
                                       <InputBaseMod
                                          data-cy="emailInput"
                                          id="email"
                                          name="email"
                                          type="email"
                                          fullWidth
                                          placeholder="Usuario"
                                          sx={{
                                             bgcolor: "#64748BC2",
                                             color: "white",
                                             borderTopRightRadius: 5,
                                             borderBottomRightRadius: 5,
                                             px: 3,
                                             boxShadow: "0px 1.5px 2px grey",
                                             minHeight: "100%",
                                          }}
                                          icon={<PersonIcon sx={{ color: "white", fontSize: "30px" }} />}
                                       />
                                    </Box>

                                    <Box sx={{ display: "flex", minHeight: 60, mt: 3, minWidth: "100%" }}>
                                       <InputBaseMod
                                          data-cy="passwordInput"
                                          id="password"
                                          name="password"
                                          type={showPassword ? "text" : "password"}
                                          fullWidth
                                          placeholder="Contraseña"
                                          sx={{
                                             bgcolor: "#64748BC2",
                                             color: "white",
                                             borderTopRightRadius: 5,
                                             borderBottomRightRadius: 5,
                                             px: 3,
                                             boxShadow: "0px 1.5px 2px grey",
                                             minHeight: "100%",
                                          }}
                                          icon={<LockIcon sx={{ color: "white", fontSize: "30px" }} />}
                                          eyeicon={
                                             <Tooltip title={showPassword ? "Ocultar" : "Mostrar"}>
                                                <Stack onClick={() => setShowPassword(!showPassword)}>
                                                   {showPassword ? (
                                                      <VisibilityOffIcon
                                                         sx={{
                                                            fontSize: "27px",
                                                            color: "#CCD1D1",
                                                            alignSelf: "center",
                                                            ml: 1,
                                                            ":hover": { cursor: "pointer" },
                                                         }}
                                                      />
                                                   ) : (
                                                      <VisibilityIcon
                                                         sx={{
                                                            fontSize: "27px",
                                                            color: "#CCD1D1",
                                                            alignSelf: "center",
                                                            ml: 1,
                                                            ":hover": { cursor: "pointer" },
                                                         }}
                                                      />
                                                   )}
                                                </Stack>
                                             </Tooltip>
                                          }
                                       />
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
                                       <Link
                                          data-cy={"forgotPasswordBtn"}
                                          to={"/recuperar-contrasena"}
                                          style={{
                                             textDecoration: "none",
                                             textAlign: "center",
                                             margin: 16,
                                             color: "#142c44",
                                             fontSize: 15,
                                          }}
                                       >
                                          ¿Olvidaste tu contraseña?
                                       </Link>
                                    </Box>
                                    <LoadingButton
                                       datacy={"loginBtn"}
                                       label={"Iniciar sesión"}
                                       isLoading={isLoading || isLoadingUserDetails}
                                       type="submit"
                                    />
                                 </Box>
                              </Form>
                           </Formik>
                        </Box>
                     )}
                  </Box>
               </Container>

               <Box
                  sx={{
                     position: "absolute",
                     right: 40,
                     bottom: 40,
                  }}
               >
                  <Fab
                     onClick={() => {
                        setSupportOpen(true);
                     }}
                     sx={{
                        bgcolor: "#162c44",
                        "&:hover": {
                           bgcolor: "#162c44",
                        },
                     }}
                  >
                     <SupportAgentIcon sx={{ color: "white" }} />
                  </Fab>
               </Box>
            </Box>
         </div>
      </SystemThemeConfig>
   );
};
