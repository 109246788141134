import React, { useState, useContext, useMemo } from "react";
import { Typography, Stack, Button, Box, IconButton, Collapse, Select, MenuItem } from "@mui/material";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import ControllingBeneficiaryModal from "../../components/MiLecosy/ModalsGovernanceModule/ControllingBeneficiaryModal";
import { DownloadRounded, Folder, InsertDriveFile } from "@mui/icons-material";
import { UpdateModal } from "../../components/MiLecosy/ModalsGovernanceModule/updateModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { FilterComponent } from "../../components/FilterComponent";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { BeneficiaryControlSheetModal2 } from "../../components/MiLecosy/ModalsGovernanceModule/beneficiaryControlSheetModal2";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { IBeneficiary } from "../../types/beneficiaryTypes";

const headers: HeadCell[] = [
   { field: "check", headerName: "check", type: "check", width: 50, align: "center", icon: "check" },
   { field: "name", headerName: "Nombre", type: "string", width: 170, align: "left" },
   { field: "rfc", headerName: "RFC", type: "string", width: 170, align: "left" },
   { field: "identifier", headerName: "Identificador", type: "string", width: 170, align: "left" },
   { field: "sheet", headerName: "Ficha", type: "string", width: 50 },
   { field: "createdAt", headerName: "Fecha de registro", type: "date", width: 145 },
   { field: "updatedAt", headerName: "Última modificación", type: "date", width: 165 },
   { field: "active", headerName: "Estatus", type: "boolean", width: 70 },
   { field: "file", headerName: "Expediente", type: "string", width: 70 },
];

export const BeneficiarioControladorScreen = () => {
   const { members, bodies } = useContext(CorporateDataContext);
   const { beneficiaries, refetch, handleDownload } = useContext(BeneficiaryControllerContext);
   const [filteredRows, setFilteredRows] = useState([]);
   const [isControllingBeneficiaryOpen, setControllingBeneficiaryOpen] = useState(false);
   const [isUpdateModal, setUpdateModal] = useState(false);
   const [isControlSheetOpen, setIsControlSheetOpen] = useState(false);
   const [beneficiary, setBeneficiary] = useState(null);
   const [selectedRows, setSelectedRows] = useState([]);
   const [openDownloadModal, setOpenDownloadModal] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [downloadProgress, setDownloadProgress] = useState(0);
   const navigate = useNavigate();
   const location = useLocation();
   const { companyId } = useParams();
   const [download, setDownload] = useState("");
   const [alreadyDownloading, setAlreadyDownloading] = useState(false);

   const handleFolderClick = (user: IBeneficiary) =>
      navigate(`${location.pathname.replace(/[^/]+$/, `expediente/${user?.folderId}`)}`);
   const handleUpdateConfig = () => navigate(`/beneficiario-controlador/${companyId}/configuracion`);

   const rows = useMemo(() => {
      if (!beneficiaries) return [];
      function addCollapsible(item, formattedRows) {
         const searchParent = formattedRows.filter((beneficiary) => beneficiary.moralAssociation?._id === item._id);
         if (searchParent.length === 0) return item;
         searchParent.forEach((element) => {
            if (element.moralAssociation) {
               const value = addCollapsible(element, formattedRows);
               if (value._id === element._id) {
                  item.collapsible = item.collapsible ? [...item.collapsible, value] : [value];
               }
            }
         });
         return item;
      }
      const formattedRows = beneficiaries.reduce((acc, beneficiary) => {
         const formattedRow = {
            ...beneficiary,
            active: !beneficiary.active,
            name:
               beneficiary.businessName ||
               (beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name),
            identifier: `${beneficiary.identificationSub ? `${beneficiary.identificationSub}-` : ""}${
               (beneficiary.identification as any).text
            }`,
            nameOg: beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name,
            rfc: beneficiary.rfc ?? beneficiary.businessRFC,
            sheet: (
               <IconButton
                  onClick={() => {
                     setIsControlSheetOpen(true);
                     setBeneficiary(beneficiary);
                  }}
               >
                  <InsertDriveFile sx={{ color: "#64748b", fontSize: 27 }} />
               </IconButton>
            ),
            file: (
               <IconButton onClick={() => handleFolderClick(beneficiary)}>
                  <Folder style={{ cursor: "pointer" }} sx={{ color: "#F1B44C", fontSize: 27 }} />
               </IconButton>
            ),
         };
         acc.push(formattedRow);
         return acc;
      }, []);
      const newValues = formattedRows
         .filter((element) => !element.moralAssociation)
         .map((element) => addCollapsible(element, formattedRows));
      setFilteredRows(newValues);
      setIsLoading(false);
      return newValues;
   }, [beneficiaries]);

   return (
      <>
         <Box
            sx={{
               justifyContent: "center",
               display: "flex",
               flexDirection: "column",
               paddingTop: 1,
               paddingBottom: 2,
            }}
         >
            <Stack
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               sx={{
                  columnGap: 2,
               }}
            >
               <Collapse in={selectedRows.length > 0} unmountOnExit>
                  <Button variant="contained" onClick={() => setOpenDownloadModal(true)}>
                     <DownloadRounded />
                  </Button>
               </Collapse>

               <FilterComponent
                  fullWidth
                  originaltableOneRows={rows}
                  tableOneFilteredRows={filteredRows}
                  setTableOneFilteredRows={setFilteredRows}
                  disableRefresh
                  filters={[]}
                  searchBar={
                     <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={handleUpdateConfig}
                        >
                           Configurar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={() => setUpdateModal(true)}
                        >
                           Solicitar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3 }}
                           onClick={() => setControllingBeneficiaryOpen(true)}
                        >
                           Agregar nuevo
                        </Button>
                     </Stack>
                  }
               />
            </Stack>
         </Box>

         <TableComponent
            headerColor
            headers={headers}
            rows={filteredRows}
            defaultColumnToOrder="name"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            emptyDataText={rows.length > 0 ? "No se encontraron beneficiarios" : "No existen beneficiarios registrados"}
            loader={isLoading}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onClick={() => {}}
            selectedRows={selectedRows}
            setSelected={setSelectedRows}
            multiselect
         />

         {isControlSheetOpen && !isLoading && (
            <BeneficiaryControlSheetModal2
               open={isControlSheetOpen}
               setOpen={setIsControlSheetOpen}
               beneficiaryId={beneficiary._id}
            />
         )}

         {isControllingBeneficiaryOpen && !isLoading && (
            <ControllingBeneficiaryModal
               state={isControllingBeneficiaryOpen}
               setState={setControllingBeneficiaryOpen}
               onConfirm={() => {
                  refetch();
                  setControllingBeneficiaryOpen(false);
               }}
               timeStamp={true}
               companyId={companyId}
               members={_.uniqBy(bodies.map((body) => body.usersData).flat(), (i) => i.user)}
               beneficiaries={rows}
            />
         )}

         {isUpdateModal && !isLoading && (
            <UpdateModal
               state={isUpdateModal}
               setState={setUpdateModal}
               onConfirm={() => {
                  refetch();
                  setUpdateModal(false);
               }}
               companyId={companyId}
               members={members}
               beneficiaries={rows}
            />
         )}

         {openDownloadModal && !isLoading && (
            <ConfirmationModal
               open={openDownloadModal}
               onConfirm={() =>
                  handleDownload(
                     setOpenDownloadModal,
                     rows,
                     selectedRows,
                     setDownloadProgress,
                     download,
                     setAlreadyDownloading
                  )
               }
               setOpen={setOpenDownloadModal}
               title={"Descargar"}
               isLoading={alreadyDownloading}
               body={
                  <Stack sx={{ justifyContent: "center", alignItems: "center", rowGap: 1 }}>
                     {alreadyDownloading && downloadProgress === 0 && download !== "expedient" ? (
                        <Stack>
                           <Typography>Creando fichas, espere un momento.</Typography>
                        </Stack>
                     ) : alreadyDownloading && download !== "sheet" ? (
                        <>
                           <Typography>Descargando expediente(s)</Typography>
                           <Typography variant="caption" sx={{ color: "red" }}>
                              Esta acción puede tardar algunos minutos en completarse.
                           </Typography>
                           {downloadProgress > 0 && (
                              <>
                                 <Typography>Progreso estimado:</Typography>
                                 <CircularProgressWithLabel value={downloadProgress} size={60} />
                              </>
                           )}
                        </>
                     ) : (
                        <>
                           <Typography>Seleccionar lo que se desea descargar</Typography>
                           <Select
                              value={download}
                              fullWidth
                              placeholder="Seleccionar"
                              size="small"
                              onChange={(event) => {
                                 const {
                                    target: { value },
                                 } = event;
                                 setDownload(value);
                              }}
                           >
                              <MenuItem value={"expedientAndSheet"}>Expediente(s) y ficha(s)</MenuItem>
                              <MenuItem value={"sheet"}>Ficha(s)</MenuItem>
                              <MenuItem value={"expedient"}>Expediente(s)</MenuItem>
                           </Select>
                        </>
                     )}
                  </Stack>
               }
            />
         )}
      </>
   );
};

export default BeneficiarioControladorScreen;
