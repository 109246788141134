import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UserContext } from "../../../context/userContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { HeadCell, TableComponent } from "../../TableComponent";
import {
   getCompaniesAndBranchCountByGroup,
   getManyCompaniesById,
   getManyCorporateData,
} from "../../../lib/usersBEClient";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { useNavigate } from "react-router-dom";

export const GroupServiceCompaniesTable = ({ serviceId }) => {
   const { isImplementationUser, user } = useContext(UserContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   let columns: HeadCell[] = [
      { field: "logo", headerName: "Logo", type: "string" },
      { field: "name", headerName: "Empresa", type: "string" },
      serviceId === "64e784dd978b71bd4f011ee3"
         ? { field: "branches", headerName: "Sucursales", type: "string" }
         : { field: "councilType", headerName: "Tipo de consejo", type: "string" },
      { field: "status", headerName: "Estatus", type: "boolean" },
   ];

   const fetchCompaniesInfo = useCallback(async () => {
      setIsLoading(true);
      try {
         const [groupCompanies, complaintsCompanyGroup, companyCorporateDatas] = await Promise.all([
            getManyCompaniesById(user.group.companies),
            getCompaniesAndBranchCountByGroup(user.group._id),
            getManyCorporateData(user.group.companies),
         ]);
         const filteredCompanies = groupCompanies.filter((company) =>
            company.company_details.servicesDetails.some((s) => s.serviceId === serviceId && !s.disable)
         );
         const companyData = filteredCompanies.map((company) => {
            const corporateData = companyCorporateDatas.find((c) => c.companyId === company._id);
            const complaintData = complaintsCompanyGroup.find((c) => c._id === company._id);
            return {
               _id: company._id,
               logo: <img src={company.logo} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />,
               name: company.person_details.comercialName,
               status: company.disabled,
               councilType: corporateData?.councilType ?? "---",
               branches: complaintData?.branches ?? "0",
            };
         });

         setCompaniesRow(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      } finally {
         setIsLoading(false);
      }
   }, [user.group, serviceId]);

   useEffect(() => {
      fetchCompaniesInfo();
      // eslint-disable-next-line
   }, [fetchCompaniesInfo]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} sx={{ px: 4, pt: 3, justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: 700 }}>Empresas</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText={
               companiesRow.length > 0 ? "No se encontró una empresa con ese nombre" : "No existen empresas registradas"
            }
            disableBorders
            headers={columns}
            loader={isLoading}
            rows={companiesRow}
            rowsPerPageOptions={[5, 10, 20]}
            onClick={(e, row) => {
               isImplementationUser()
                  ? navigate(`/mi-lecosy/${row._id}/servicios`)
                  : navigate(`/mi-lecosy/${row._id}/inicio`);
            }}
         />
      </Box>
   );
};
