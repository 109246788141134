import { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid, Button, CircularProgress, Divider } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getBranchesByCompanyId, getUrlS3 } from "../../../lib/usersBEClient";
import { SupportContext } from "../../../context/supportContext";
import { UserContext } from "../../../context/userContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { getDateFromISODate } from "../../../const/globalConst";

interface CompanyProps {
   companyData?: any;
}

export const GroupInformationComponent = (props: CompanyProps) => {
   const { user } = useContext(UserContext);
   const [contractFile, setContractFile] = useState("");
   const { setSupportOpen, supportOpen, setUpdateData } = useContext(SupportContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companyBranches, setCompanyBranches] = useState([]);

   const getDataS3 = async (id: string) => {
      if (user.group && !props.companyData) {
         const contractUrl: string = await getUrlS3(
            "files-lecosy",
            { folder: `contracts/group/${id}` },
            "contract.pdf"
         );
         setContractFile(contractUrl);
      } else {
         const contractUrl: string = await getUrlS3("files-lecosy", { folder: `contracts/${id}` }, "contract.pdf");
         setContractFile(contractUrl);
      }
   };

   useEffect(() => {
      if (props.companyData) {
         const companyId = props.companyData._id;
         getBranchesByCompanyId(props.companyData._id)
            .then((comp) => setCompanyBranches(comp.data))
            .catch((err) => showSnackBar("Error al cargar sucursales", true));
         getDataS3(companyId);
      } else if (user.group?._id) {
         getDataS3(user.group._id);
      }
      // eslint-disable-next-line
   }, [user.group, props.companyData]);

   return (
      <>
         <Box p={1} sx={{ bgcolor: "white", boxShadow: 2 }}>
            {user.group && !props.companyData ? (
               <>
                  <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                     <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Denominación o razón social:</Typography>
                           <Typography>{user.group.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Teléfono:</Typography>
                           <Typography>{user.group.phoneNumber || "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Tipo de cliente:</Typography>
                           <Typography>{"Grupo empresarial"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Empresas:</Typography>
                           <Typography>{user.group.companies.length}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} mb={1}>
                              Contrato:
                           </Typography>
                           {user.group && contractFile ? (
                              <Button
                                 variant="outlined"
                                 onClick={async () => {
                                    window.open(contractFile, "_blank", "noopener,noreferrer");
                                 }}
                              >
                                 <Typography>Ver contrato</Typography>
                                 <PictureAsPdfIcon sx={{ mx: 1 }} />
                              </Button>
                           ) : (
                              "No tiene políticas"
                           )}
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Fecha de creación: </Typography>
                           <Typography>{getDateFromISODate(user.group.createdAt)}</Typography>
                        </Grid>
                     </Grid>
                  </Box>
                  <Divider />
                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={2}>
                     <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Typography fontWeight={600}>Última modificación:</Typography>
                        <Typography mx={0.5}>
                           {user.group.updatedAt ? getDateFromISODate(user.group.updatedAt) : ""}
                        </Typography>
                     </Box>
                     <Button
                        variant="contained"
                        onClick={() => {
                           setSupportOpen(!supportOpen);
                           setUpdateData(true);
                        }}
                     >
                        <Typography fontSize={14}>Actualizar información</Typography>
                     </Button>
                  </Box>
               </>
            ) : (
               <>
                  <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                     {props.companyData ? (
                        <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Denominación o razón social:</Typography>
                              <Typography>
                                 {props.companyData?.person_details?.businessName
                                    ? props.companyData.person_details.businessName
                                    : ""}
                              </Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Actividad y/o giro:</Typography>
                              <Typography>
                                 {props.companyData?.person_details?.activity
                                    ? props.companyData.person_details.activity
                                    : "No tiene actividad registrada"}
                              </Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Tipo de cliente:</Typography>
                              <Typography>{props.companyData.type === "legal" ? "Moral" : "Físico"}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Teléfono:</Typography>
                              <Typography>{props.companyData.invoice_details?.phone || "Sin datos"}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Sucursales:</Typography>
                              <Typography>{companyBranches.length}</Typography>
                           </Grid>

                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Fecha de creación:</Typography>
                              <Typography>
                                 {props.companyData
                                    ? getDateFromISODate(props.companyData?.createdAt || new Date())
                                    : ""}
                              </Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Módulos contratados:</Typography>
                              <Typography>{props.companyData?.company_details?.servicesDetails.length}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Estatus:</Typography>
                              <Typography>{props.companyData?.disable ? "Inhabilitado" : "Habilitado"}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600} mb={1}>
                                 Contrato:
                              </Typography>
                              {contractFile ? (
                                 <Button
                                    variant="outlined"
                                    onClick={async () => {
                                       window.open(contractFile, "_blank", "noopener,noreferrer");
                                    }}
                                 >
                                    <Typography>Ver contrato</Typography>
                                    <PictureAsPdfIcon sx={{ mx: 1 }} />
                                 </Button>
                              ) : (
                                 "No tiene contrato"
                              )}
                           </Grid>
                        </Grid>
                     ) : (
                        <CircularProgress />
                     )}
                  </Box>
                  <Divider />
                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={2}>
                     <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Typography fontWeight={600}>Última modificación:</Typography>
                        <Typography mx={0.5}>
                           {props.companyData?.updatedAt
                              ? getDateFromISODate(props.companyData.updatedAt || new Date())
                              : ""}
                        </Typography>
                     </Box>

                     <Button
                        variant="contained"
                        onClick={() => {
                           setSupportOpen(!supportOpen);
                           setUpdateData(true);
                        }}
                     >
                        <Typography fontSize={14}>Actualizar información</Typography>
                     </Button>
                  </Box>
               </>
            )}
         </Box>
      </>
   );
};
