import { Box, Container, Modal, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { NotificationBar } from "../../components/notificationsBar";
import { TopHeader } from "./TopHeader";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { Outlet, useLocation, useNavigate, useParams, useBeforeUnload } from "react-router-dom";
import { SecondTopMenu } from "./SecondTopMenu";
import { UserContext } from "../../context/userContext";
import { CompanySelectorModal } from "../../drawer/CompanySelectorModal";
import { ManifestoModal } from "../../components/Gobierno corporativo/SessionComponent/ManifestoModal";
import InfoIcon from "@mui/icons-material/Info";
import { GovernanceSessionContext } from "../../context/governanceContext/governanceSessionContext";
import { SecurityQuestionModal } from "../../components/Gobierno corporativo/SessionComponent/SecurityQuestionModal";
import { SessionNotification } from "../../components/Gobierno corporativo/SessionComponent/SessionNotification";
import { GovernanceSelectionModal } from "./GovernanceSelectionModal";
import CreateProfileBeneficiary from "../../components/Beneficiary Controller/CreateProfileBeneficiary";
import { CreateProfileContext } from "../../context/beneficiaryContext/createProfileContext";
import BeneficiaryRequestsModal from "../../components/Beneficiary Controller/BeneficiaryRequestsModal";

export const MainScreen = () => {
   const { companyId, id } = useParams();
   const {
      setSelectedResources,
      isLoadingUser,
      openCompanySelector,
      setOpenCompanySelector,
      user,
      companies,
      resources,
      getCompanyDetails,
      setCompanySelected: setCompanySelectedContext,
      companySelected: selectedCompany,
   } = useContext(UserContext);
   const {
      setCompanySelected,
      setSelectedGovernance,
      gobernanceBody,
      isLoading: isLoadingGovernance,
      companySelected,
      hideSecondTopMenu,
      selectedGovernance,
      consultiveGroup,
      openGovernanceSelection,
      setOpenGovernanceSelection,
   } = useContext(GovernanceContext);
   const navigate = useNavigate();
   const [manifiestoModalOpen, setManifiestoModalOpen] = useState(false);
   const {
      sessionFound,
      inSession,
      openSecurityQuestionModal,
      setOpenSecurityQuestionModal,
      session,
      sessionFoundArray,
      hideNotification,
      setHideNotification,
      socket,
   } = useContext(GovernanceSessionContext);
   const { openProfileModal, setOpenProfileModal, requestModal, setRequestModal } = useContext(CreateProfileContext);

   const location = useLocation();

   const handleCloseManifiesto = () => {
      setManifiestoModalOpen(false);
   };

   const handleCloseSecurityQuestion = () => {
      setOpenSecurityQuestionModal(false);
   };

   useBeforeUnload(() => socket.disconnect());

   useEffect(() => {
      const fetchInfo = () => {
         if (!consultiveGroup || (consultiveGroup && companyId !== consultiveGroup?._id)) {
            if (companyId !== "" && companyId !== undefined) {
               if (companies.length > 0) {
                  setCompanySelected(companyId);
                  getCompanyDetails(companyId);
                  if (location.pathname.endsWith(companyId)) navigate(`${companyId}/mi-cuenta/dashboard`);
               }
            } else if (companies && companies.length === 1) {
               setCompanySelected(companies[0]?._id);
               getCompanyDetails(companies.find((c) => c._id === companyId)?._id);
               navigate(`${companies[0]?._id}/mi-cuenta/dashboard`);
            } else if ((!companyId || !companySelected) && (companies.length > 1 || user.group))
               setOpenCompanySelector(true);
         } else if (consultiveGroup && companyId === consultiveGroup._id) {
            setCompanySelectedContext(null);
            setCompanySelected(consultiveGroup._id);
            if (location.pathname.endsWith(consultiveGroup._id)) navigate(`${consultiveGroup._id}/mi-cuenta/dashboard`);
         }
         if (id && !isLoadingGovernance) {
            setSelectedGovernance(gobernanceBody.find((governance) => governance._id === id));
            if (selectedGovernance?.title && consultiveGroup && companyId === consultiveGroup._id) {
               getCompanyDetails(selectedGovernance.company);
            }
         }
      };
      if (!isLoadingUser && gobernanceBody) fetchInfo();
   }, [companyId, user, isLoadingGovernance, gobernanceBody, consultiveGroup]);

   useEffect(() => {
      if (!isLoadingUser) {
         const validation = resources.filter((access) => {
            return access.name === getName(location.pathname.split("/")[4]);
         });
         setSelectedResources(validation[0]);
      }
   }, [location, isLoadingUser, user]);

   const getName = (name: string) => {
      let sectionString = "";
      switch (name) {
         case "calendario":
            sectionString = "Calendar";
            break;
         case "acuerdos":
            sectionString = "Board";
            break;
         case "notas":
            sectionString = "Notes";
            break;
         case "archivos":
            sectionString = "Files";
            break;
         case "reportes":
            sectionString = "Reports";
            break;
         case "documentos":
            sectionString = "Files";
            break;
         case "miembros":
            sectionString = "Members";
            break;
         case "chat":
            sectionString = "Chat";
            break;
         default:
            break;
      }
      return sectionString;
   };

   return (
      <Box p={2}>
         {(sessionFound || sessionFoundArray.length !== 0) && !hideNotification && !inSession && (
            <SessionNotification
               setManifiestoModalOpen={setManifiestoModalOpen}
               setHideNotification={setHideNotification}
            />
         )}

         {(sessionFound || sessionFoundArray.length !== 0) && hideNotification && !inSession && (
            <Box sx={{ height: "100%", position: "absolute", right: 0, zIndex: 1000 }}>
               <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 3, position: "fixed", right: 0, top: "8vh" }}
               >
                  <Box
                     sx={{
                        bgcolor: "#bdc8d3",
                        color: "#162C44",
                        fontWeight: 600,
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        p: 1.5,
                        cursor: "pointer",
                     }}
                     onClick={() => setHideNotification(false)}
                  >
                     <InfoIcon sx={{ mr: 1 }} />
                  </Box>
               </Box>
            </Box>
         )}

         {companySelected === consultiveGroup?._id ? (
            <NotificationBar
               head={
                  <Box sx={{ display: "flex", columnGap: 1 }}>
                     <Typography variant="h6">{`Gobierno corporativo: `}</Typography>
                     <Typography
                        variant="h6"
                        sx={{ ":hover": { cursor: "pointer", color: "#162c4490" } }}
                        onClick={() => setOpenGovernanceSelection({ open: true, title: selectedGovernance?.title })}
                     >
                        {(selectedGovernance && selectedGovernance.company === consultiveGroup?._id) ||
                        selectedGovernance?.title === "PANEL DE USUARIO"
                           ? user.group?.name
                           : selectedCompany?.person_details?.businessName}
                     </Typography>
                  </Box>
               }
            />
         ) : (
            <NotificationBar head={`Gobierno corporativo: ${selectedCompany?.person_details?.businessName ?? ""}`} />
         )}

         <Container maxWidth="xl">
            <Stack spacing={2} sx={{ maxWidth: 1380 }}>
               <TopHeader loading={isLoadingGovernance} goberningBody={gobernanceBody} />
               {hideSecondTopMenu && <SecondTopMenu />}
               <Outlet />
            </Stack>
         </Container>
         <Modal open={openCompanySelector} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CompanySelectorModal state={openCompanySelector} setState={setOpenCompanySelector} />
         </Modal>
         <Modal
            open={openGovernanceSelection.open}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
         >
            <GovernanceSelectionModal setState={setOpenGovernanceSelection} state={openGovernanceSelection} />
         </Modal>

         {!isLoadingUser && !isLoadingGovernance && (
            <>
               <Modal
                  open={manifiestoModalOpen}
                  onClose={handleCloseManifiesto}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
               >
                  <ManifestoModal
                     session={sessionFound || session}
                     user={user}
                     gobernanceBody={sessionFound ? sessionFound.governance : session?.governance}
                     setManifiestoModalOpen={setManifiestoModalOpen}
                  />
               </Modal>
               <Modal
                  open={openSecurityQuestionModal}
                  onClose={handleCloseSecurityQuestion}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
               >
                  <SecurityQuestionModal />
               </Modal>
            </>
         )}
         <CreateProfileBeneficiary open={openProfileModal && !openCompanySelector} setOpen={setOpenProfileModal} />
         <BeneficiaryRequestsModal
            open={requestModal && !openCompanySelector && !openProfileModal}
            setOpen={setRequestModal}
         />
      </Box>
   );
};
