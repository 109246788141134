import { useEffect, useState, useContext, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { UserContext } from "../../context/userContext";
import { AnyObject } from "yup/lib/types";
import { FilterComponent } from "../FilterComponent";
import { AddCircle } from "@mui/icons-material";
import { getUserStatus } from "../../lib/usersBEClient";
import { useNavigate } from "react-router-dom";
import { AddUserModal } from "./AddUserModal";
import { getMemberChargesByCompany } from "../../lib/gobCorpBEClient";
import rolesByService from "../../const/rolesByService.json";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";

export const CompanyUsersTable = () => {
   const { user, companySelected } = useContext(UserContext);
   const { consultiveGroup } = useContext(CorporateDataContext);
   const navigate = useNavigate();
   const [companyUsers, setCompanyUsers] = useState([]);
   const [filteredUsers, setFilteredUsers] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [openModal, setOpenModal] = useState(false);
   const [groupUsers, setGroupUsers] = useState([]);
   const [userStatus, setUserStatus] = useState([]);

   const isMemberOfGroup = useMemo(() => {
      if (consultiveGroup) return consultiveGroup?.companies.some((c) => c === companySelected._id);
      else return false;
   }, [consultiveGroup]);

   const headCells: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "status", headerName: "Estatus", type: "boolean" },
   ];

   const fetchCompanyUsers = async () => {
      setIsLoading(true);
      try {
         const checkContext = companySelected?.company_details.users;
         if (!checkContext || checkContext.length === 0) return;
         const usersData: any[] = (checkContext as any[]).map((user) => ({
            _id: user._id,
            uid: user.uid,
            name: user.firstName + " " + user.lastName,
            email: user.email,
            roles: getCompanyRoles(user.role).length > 0 ? getCompanyRoles(user.role) : ["sin datos"],
            status: userStatus.find((u) => u._id === user._id).disabled,
         }));

         const gcUserIds = usersData
            .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
            .map((user) => user._id);

         if (gcUserIds.length > 0) {
            const GcChargesResponse = await getMemberChargesByCompany(gcUserIds, companySelected?._id);
            for (const user of usersData) {
               const index = GcChargesResponse.findIndex((c) => c.user === user._id);
               if (index >= 0) {
                  if (isMemberOfGroup) {
                     user.roles = user.roles.concat(GcChargesResponse[index].charges.find((c) => c === "Accionista"));
                     user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
                     if (user.roles.includes(undefined)) user.roles = ["sin datos"];
                  } else {
                     user.roles = user.roles.concat(GcChargesResponse[index].charges);
                     user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
                  }
               }
            }
         }

         const usersDataNoAdmin = usersData.filter(
            (a) => a._id !== user.id && !a.roles.includes("Usuario de implementación")
         );

         setIsLoading(false);
         setCompanyUsers(usersDataNoAdmin);
         setFilteredUsers(usersDataNoAdmin);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   };

   const getCompanyRoles = (roles: AnyObject[]) => {
      const roleNames = roles.map((role) => role);
      const rolesArray = [];
      for (const role of roleNames)
         if (role.company === companySelected._id) rolesArray.push(role.roles.map((role) => role.name));
      return rolesArray.flat();
   };

   const handleClickRowUser = async (_e, row) => {
      const userId = row.uid;
      const screen = "mi-lecosy/usuarios/1/" + userId;
      return navigate(`/${screen}`);
   };

   useEffect(() => {
      const getStatus = async () => {
         const status = await getUserStatus(companySelected?.company_details.users);
         setUserStatus(status);
      };
      getStatus();
      if (userStatus.length > 0) fetchCompanyUsers();
      // eslint-disable-next-line
   }, [userStatus.length]);

   const fetchUsers = async () => {
      setOpenModal(true);
      setGroupUsers(companySelected.company_details.users);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "flex-end",
            }}
         >
            <Box sx={{ mx: 3, width: "100%" }}>
               <FilterComponent
                  originaltableOneRows={companyUsers}
                  tableOneFilteredRows={filteredUsers}
                  setTableOneFilteredRows={setFilteredUsers}
                  filters={[]}
                  fullWidth
                  searchBar={
                     <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Button
                           variant="contained"
                           onClick={() => {
                              setOpenModal(true);
                              fetchUsers();
                           }}
                           fullWidth
                        >
                           <AddCircle sx={{ mr: 1 }} />
                           Agregar usuario
                        </Button>
                     </Box>
                  }
                  disableRefresh
               />
            </Box>
         </Box>

         <TableComponent
            defaultColumnToOrder="status"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={filteredUsers}
            onClick={(e, row) => handleClickRowUser(e, row)}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados"
            cursor={"default"}
            disableBorders
         />
         <AddUserModal company={companySelected} open={openModal} setOpen={setOpenModal} usersByModule={groupUsers} />
      </Box>
   );
};
