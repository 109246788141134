export const complianceIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Programa+de+cumplimiento.svg";
export const riskIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Gesti%C3%B3n+de+riesgos.svg";
export const correlationIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Correlaci%C3%B3n+de+leyes.svg";
export const auditIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Auditor%C3%ADa+interna+.svg";
export const practicesIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Buenas+pr%C3%A1cticas.svg";
export const complaintIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Canal+de+denuncia.svg";
export const governanceIcon = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icon_Governance.png";
export const beneficiaryControllerIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+de+m%C3%B3dulos+LECOSY_Beneficiario+Controlador-08.svg";
export const logo_Lecosy_Blanco = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png";
export const logo_Isotipo_Blanco =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/isotipo/Lecosy_icono+blanco.png";
export const logo_Lecosy = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png";
export const link = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+DE+ENLACE+Y+CORREO_e.png";
export const mail = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+DE+ENLACE+Y+CORREO_c.png";
export const qr = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/qr.png";

export const ci_triangle_check =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/ci_triangle-check.svg";
export const fluent_building_home =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/fluent_building-home-20-filled.svg";
export const fluent_building_multiple =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/fluent_building-multiple-24-filled.svg";
export const report_warning =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/fluent-mdl2_report-warning.svg";
export const baseline_no_account =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/ic_baseline-no-accounts.svg";
export const round_report_problem =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/ic_round-report-problem.svg";
export const iconamoon_check_bold =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/iconamoon_check-bold.svg";
export const material_symbols_report_off =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/material-symbols_report-off.svg";
export const material_symbols_scan_delete =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/material-symbols_scan-delete-outline-rounded.svg";
export const mingcute_report_fill =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/mingcute_report-fill.svg";
export const mingcute_user =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/mingcute_user-4-fill.svg";
export const octicon_report =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icons-cd-graphics/octicon_report-16.svg";
