import { Box, Button, Drawer, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { HeadCell, TableComponent } from "../../TableComponent";
import {
   RemoveCompanySubUserRolesById,
   getAccessByServiceId,
   getBranchesByCompanyId,
   getCompanyById,
   getUrlS3,
} from "../../../lib/usersBEClient";
import { ModifyUserRoles } from "../../Drawers/ModifyUserRoles";
import { ConfirmationModal } from "../../ConfirmationModal";
import { SnackBarContext } from "../../../context/snackBarContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddCompanyUserModal } from "./Modal/AddCompanyUserModal";
import { UserContext } from "../../../context/userContext";
import { CustomModalComponent } from "../../CustomModalComponent";
import { getChargeByGovernUsers, getGoberningBodiesByCompany } from "../../../lib/gobCorpBEClient";

const groupColums: HeadCell[] = [
   {
      field: "logo",
      headerName: "Logo",
      type: "string",
      width: 100,
   },
   {
      field: "name",
      headerName: "Nombre de la empresa",
      type: "string",
      width: 100,
   },
   {
      field: "abrir",
      headerName: "",
      type: "collapsible",
      width: 500,
      align: "right",
   },
];

interface TableUserComponentProps {
   userInformation: any;
   fetchData: Function;
   selectedCompanyData: any;
   setSeed?: Function;
}

const getGovernanceCharge = async (userId: string, companyId: string, charges: string[]) => {
   const gcResponse = await getGoberningBodiesByCompany(companyId);
   if (!gcResponse) return [];
   const GCCharges = [];
   for (const governanceBody of gcResponse)
      for (const user of governanceBody.users) if (user.user === userId) GCCharges.push(user.charge);
   const chargeData = getChargeByGovernUsers(GCCharges).then((chargesS) =>
      chargesS.filter((c) => charges.includes(c._id)).map((s) => s.chargeName)
   );
   return chargeData;
};

export const TableUserComponent = (props: TableUserComponentProps) => {
   const { user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [userData, setUserData] = useState([]);
   const [opened, setOpened] = useState<string>();
   const [openModifySubUserDrawer, setOpenModifySubUserDrawer] = useState(false);
   const [userInformation, setUserInformation] = useState(null);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [companySelected, setCompanySelected] = useState(null);
   const [isLoadingModules, setisLoadingModules] = useState(true);
   const [openSubUserDeleteConfirmModal, setOpenSubUserDeleteConfirmModal] = useState(false);
   const [companyToDelete, setCompanyToDelete] = useState("");
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingDelete } = RemoveCompanySubUserRolesById(
      props.userInformation.user.uid,
      companyToDelete
   );
   const [openModal, setOpenModal] = useState<boolean>(false);

   const fetchRolesInfo = async () => {
      const userData: any[] = [];
      setIsLoading(true);
      for (const userU of props.userInformation.user.role) {
         if (userU.group) continue;
         const logo = await getDataS3(userU.company);
         const modules = [];
         const companyData = await getCompanyById(userU.company);
         for (const iterator of companyData.data.company_details.servicesDetails) {
            const { data } = await getAccessByServiceId(iterator.serviceId._id);
            const module = {
               module: iterator.serviceId.service,
               company: userU.company,
               roles: [],
            };
            for (const rol of data.roles)
               if (userU.roles.some((role) => role === rol._id)) {
                  if (["N1", "N2", "N4", "N5", "N6", "N7", "N8", "N9"].includes(rol.name)) {
                     const GCResponse = await getGovernanceCharge(
                        props.userInformation.user._id,
                        userU.company,
                        rol.charges
                     );
                     if (GCResponse.length > 0) {
                        rol.chargeName = GCResponse;
                     }
                  }
                  module.roles.push(rol);
               }
            modules.push(module);
         }
         userData.push({
            id: companyData.data._id,
            logo: logo,
            name: companyData.data.person_details.comercialName,
            modules: modules,
         });
      }
      setUserData(userData);
      setIsLoading(false);
   };

   useEffect(() => {
      fetchRolesInfo();
   }, []);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return <img defaultValue={logoUrl} src={logoUrl} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />;
   };

   useEffect(() => {
      const getModules = async () => {
         setisLoadingModules(true);
         const services = [];
         const companyData = await getCompanyById(companySelected.company);
         for (const service of companyData.data.company_details.servicesDetails) {
            const { data } = await getAccessByServiceId(service.serviceId._id);
            let branches = [];
            if (data.serviceName === "Canal de denuncias") {
               const branchesResponse = await getBranchesByCompanyId(companySelected.company);
               branches = branchesResponse.data;
            }
            const provisionalData = {
               company: companySelected.company,
               service: data,
               userLimit: service.userLimit,
               branches: branches,
            };
            services.push(provisionalData);
         }
         setisLoadingModules(false);
         setModulesAndRoles(services);
      };
      if (companySelected) {
         getModules();
      }
   }, [companySelected]);

   const handleOpenModal = (e, row, companyInfo) => {
      if (companySelected && row.company._id === companySelected.company) {
         setisLoadingModules(false);
      }
      setCompanySelected(row);
      const generalRoles = [];
      for (const company of companyInfo) {
         for (const iterator of company.roles) {
            generalRoles.push(iterator);
         }
      }
      setUserInformation({
         ...row,
         branches: props.userInformation.userDetails.branches,
         uid: props.userInformation.user.uid,
         _id: props.userInformation.user._id,
         generalRoles: generalRoles,
      });
      setOpenModifySubUserDrawer(true);
   };

   const handleDeleteCompany = () => {
      mutate(
         {},
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar rol al usuario.", true);
            },
            onSuccess: async () => {
               props.fetchData();
               showSnackBar("El usuario fue agregado correctamente.", false);
               setOpenSubUserDeleteConfirmModal(false);
            },
         }
      );
   };

   const handleOpenDeleteModal = (e, row) => {
      const companyId = row[0].company._id;
      setCompanyToDelete(companyId);
      setOpenSubUserDeleteConfirmModal(true);
   };

   const handleUpdateInfo = () => props.setSeed((s) => s + 1);

   return (
      <Box mt={4}>
         <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, alignContent: "center" }}>
            <Typography fontSize={20} fontWeight={"bold"}>
               Empresas a las que tiene acceso
            </Typography>
            {user.group && !props.userInformation.userDetails.disabled && (
               <Button
                  variant="contained"
                  sx={{ borderRadius: 2, display: "none" }}
                  onClick={() => {
                     setOpenModal(true);
                  }}
               >
                  <AddCircleIcon sx={{ mr: 1 }} /> Agregar acceso
               </Button>
            )}
         </Box>
         <TableComponent
            headerColor
            headers={groupColums}
            rows={userData}
            defaultColumnToOrder="name"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            emptyDataText="No existen empresas registradas"
            loader={isLoading}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onClick={() => {}}
            collapsible
            group
            opened={opened}
            setOpened={setOpened}
            onClickCollapsible={handleOpenModal}
            onDeleteCollapsible={handleOpenDeleteModal}
         />
         <Drawer
            anchor={"right"}
            open={openModifySubUserDrawer}
            hideBackdrop={true}
            onClose={() => setOpenModifySubUserDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            {userInformation && (
               <ModifyUserRoles
                  companySelected={companySelected}
                  addModule={false}
                  userInfo={userInformation}
                  modulesAndRoles={modulesAndRoles}
                  open={openModifySubUserDrawer}
                  setOpen={setOpenModifySubUserDrawer}
                  isLoadingModules={isLoadingModules}
                  setisLoadingModules={setisLoadingModules}
                  fetchData={props.fetchData}
                  handleUpdateInfo={handleUpdateInfo}
               />
            )}
         </Drawer>
         <ConfirmationModal
            isLoading={isLoadingDelete}
            open={openSubUserDeleteConfirmModal}
            setOpen={setOpenSubUserDeleteConfirmModal}
            title="Eliminar empresa"
            body={
               <>
                  <Typography>¿Estás seguro de eliminar la empresa del usuario?</Typography>
                  <Typography>Para volver a asignar la empresa se requiere dar clic en "Añadir empresa"</Typography>
               </>
            }
            onConfirm={handleDeleteCompany}
         />
         <CustomModalComponent open={openModal} setOpen={setOpenModal} title="Agregar acceso" timeStamp>
            <AddCompanyUserModal
               userData={userData}
               setOpen={setOpenModal}
               userInformation={props.userInformation}
               selectedCompanyData={props.selectedCompanyData}
               fetchData={props.fetchData}
            />
         </CustomModalComponent>
      </Box>
   );
};
