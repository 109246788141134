import { Document, View, Page, Text, Image, Font, StyleSheet, Svg, Polygon } from "@react-pdf/renderer";

export const BillAssemblePDF = ({
   session,
   members,
   values,
   affairsArray,
   affairVotes,
   additionalVotes,
   governingBody,
   colors,
   colorsText,
   companyLogo,
   cancelled,
   signsArray,
   externalMembers,
   sessionResolutions,
}) => {
   const valuesKeys = Object.keys(values);
   let numberOfCommitments = 0;
   let otherSubjectsArray = [];

   valuesKeys.forEach((key, index) => {
      const matchActivity = key.match(/activity(\d+)/);
      const matchResponsableName = key.match(/otherSubject(\d+)/);
      if (matchActivity) numberOfCommitments++;
      else if (matchResponsableName) otherSubjectsArray.push(values[key]);
   });

   const formattedDate = new Date(session.startTime).toLocaleDateString();
   const formattedHourStart =
      session.startTime.split("T")[1].split(":")[0] + ":" + session.startTime.split("T")[1].split(":")[1];
   const finishingHour =
      new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes();

   const shareholdersArray = members.filter((user) => {
      if (user.firstName)
         if (
            !user.memberCharge.includes("Usuario de implementacion") &&
            !user.memberCharge.includes("Coordinador del Gobierno Corporativo")
         )
            return user;
   });

   const attendedList = session.usersRegistry.filter(
      (user) => shareholdersArray.some((userIntern) => userIntern._id === user.user) && user.status === "APPROVED"
   );

   let totalCompanyCapitalBySerie = {};
   let totalCompanyActions = 0;
   let totalCompanyCapital = 0;
   let companyPercentageAttendance = 0;
   const membersList = [];

   //GET ACTIONS BY USER AND SERIES
   const relevantActions = governingBody.users
      .flatMap((member) => member.actions.filter((action) => action.title && action.sharesAmount > 0))
      .map((action) => ({
         ...action,
         serie: governingBody.series.find((serie) => serie.title === action.title),
      }));

   //IF NONE OF THE USERS HAVE ACTION ASSIGNED DOESN'T DO ANYTHING
   if (relevantActions.length === 0) return;

   //SET THE TOTAL OF ACTIONS BY SERIES IN GOVERNING BODY
   relevantActions.forEach((action: any) => {
      if (action.serie) {
         totalCompanyCapitalBySerie[action.serie.title] =
            (totalCompanyCapitalBySerie[action.serie.title] ? totalCompanyCapitalBySerie[action.serie.title] : 0) +
            action.sharesAmount;
         totalCompanyActions += action.sharesAmount;
      }
   });

   governingBody["totalCompanyCapitalBySerie"] = totalCompanyCapitalBySerie;

   //SET THE TOTAL OF ACTION MULTIPLIED BY THE NOMINAL VALUE
   for (const serie of governingBody.series) {
      totalCompanyCapital +=
         (governingBody.totalCompanyCapitalBySerie[serie.title]
            ? governingBody.totalCompanyCapitalBySerie[serie.title]
            : 0) * serie.nominalValue;
   }

   governingBody["totalCompanyCapital"] = totalCompanyCapital;
   governingBody["totalCompanyActions"] = totalCompanyActions;

   for (const userInGov of governingBody.users) {
      const user: any = userInGov;
      const initialValue = 0;

      //GET THE USER TOTAL ACTIONS
      const totalActions = userInGov.actions.reduce(
         (accumulator, action) => accumulator + action.sharesAmount,
         initialValue
      );

      let socialCapital = 0;

      //GET THE USER TOTAL ACTIONS BY THE NOMINAL VALUE
      for (const serie of governingBody.series) {
         const userSerie = userInGov.actions.find((action) => action.title === serie.title);
         if (userSerie) socialCapital += userSerie.sharesAmount * serie.nominalValue;
      }

      //GET THE PERCENTAGE OF ACTIONS
      const actionPercentage = (totalActions * 100) / totalCompanyActions;

      if (attendedList.some((userAttended) => userAttended.user === userInGov.user))
         companyPercentageAttendance += actionPercentage;

      //PUSH THE USER TO THE FORMATTED ARRAY OF USERS
      membersList.push({
         id: user.user,
         percentage: Math.round(actionPercentage * 10) / 10,
         totalActions: totalActions,
         socialCapital: socialCapital,
         series: userInGov.actions,
      });
   }

   const initialValueSocialCapital = 0;
   const membersOnline = membersList.filter((memberOnline) =>
      signsArray.some((sign) => sign.userId === memberOnline.id)
   );

   companyPercentageAttendance = membersOnline.reduce(
      (accumulator, memberSocialCapital) => accumulator + memberSocialCapital.socialCapital,
      initialValueSocialCapital
   );

   companyPercentageAttendance = ((companyPercentageAttendance || 0) * 100) / governingBody.totalCompanyCapital;

   companyPercentageAttendance = Math.round(companyPercentageAttendance * 100) / 100;

   //#region repetitiveContainers

   const AdditionalVotationsContainer = (addVote, hasOrderId) => {
      return (
         <View style={{ width: "100%", display: "flex" }}>
            {!hasOrderId && (
               <Text
                  style={{
                     width: "100%",
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "1",
                     fontFamily: "open-sans-bold",
                  }}
               >
                  Votos no asociados
               </Text>
            )}
            <View key={addVote.title} style={{ borderBottom: 1, borderColor: colors.secondary }} wrap={false}>
               <View style={{ border: 1, borderColor: "#EFEFEF", paddingHorizontal: 20, paddingVertical: 10 }}>
                  <Text style={{ fontSize: "10" }}>{addVote.title}</Text>
               </View>
               <View style={{ display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap" }}>
                  {addVote.canceled ? (
                     <View style={{ width: "100%", display: "flex" }}>
                        <Text style={{ textAlign: "center", fontSize: "11", fontFamily: "open-sans-bold" }}>
                           Votación cancelada
                        </Text>
                     </View>
                  ) : (
                     addVote.votes.map((shareholder) => {
                        return votationAnswerContainer(
                           shareholder.userName,
                           shareholder.abstention ? "ABS" : shareholder.answer || "PEN"
                        );
                     })
                  )}
               </View>
            </View>
         </View>
      );
   };

   const votationAnswerContainer = (user, answer) => {
      return (
         <View style={{ display: "flex", width: "24%", marginBottom: 5, marginRight: 5 }} wrap={false}>
            <Text
               style={{
                  fontSize: "8",
                  border: 1,
                  borderColor: "#EFEFEF",
                  backgroundColor: "#EFEFEF",
                  padding: 3,
                  textAlign: "center",
                  fontFamily: "open-sans",
               }}
            >
               {user}
            </Text>
            <View
               style={{
                  display: "flex",
                  justifyContent: "center",
                  border: 1,
                  borderColor: "#EFEFEF",
                  fontFamily: "open-sans",
               }}
            >
               <Text
                  style={{
                     fontSize: "8",
                     textAlign: "center",
                     paddingVertical: 5,
                     fontFamily: "open-sans",
                  }}
               >
                  {answer}
               </Text>
            </View>
         </View>
      );
   };

   //#endregion
   return (
      <Document>
         <Page style={styles.body}>
            {/*HEADER*/}
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10",
               }}
               fixed
            >
               <Image style={{ width: 52, marginHorizontal: "10" }} src={companyLogo} />
               <View style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                  <View
                     style={{
                        backgroundColor: colors.secondary,
                        padding: "4",
                        borderTopLeftRadius: "4",
                        borderBottomRightRadius: "4",
                     }}
                  >
                     <Text style={{ fontSize: "10", textAlign: "center", color: colorsText.secondary }}>
                        {session.billCode}
                     </Text>
                  </View>
               </View>
            </View>
            <Text
               style={{
                  textAlign: "center",
                  marginBottom: "10",
                  fontFamily: "open-sans-bold",
                  fontSize: "15",
               }}
            >
               {session.type.split(" ").length > 1
                  ? session.type.toUpperCase()
                  : `ASAMBLEA ${session.type.toUpperCase()}`}
            </Text>
            {/*HORAS INICIALES Y HORAS FINAL*/}
            <View style={{ border: "1" }}>
               <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>Fecha:</Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "50%", justifyContent: "center" }}>
                        <Text style={{ fontSize: "10", color: colorsText.primary, paddingHorizontal: "7" }}>
                           {formattedDate}
                        </Text>
                     </View>
                  </View>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>
                           Domicilio social:
                        </Text>
                     </View>
                     <View
                        style={{
                           backgroundColor: colors.primary,
                           width: "50%",
                           justifyContent: "center",
                           padding: "",
                        }}
                     >
                        <Text
                           style={{
                              fontSize: "10",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {session.location !== "" ? session.location : "Sin datos"}
                        </Text>
                     </View>
                  </View>
               </View>
               {/*DATOS REUNION VIRTUAL*/}
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     borderTop: 1,
                     borderColor: "white",
                  }}
               >
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right", flex: 1 }}>
                           Enlace de acceso:
                        </Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "50%", justifyContent: "center" }}>
                        <Text style={{ fontSize: "10", color: colorsText.primary, paddingHorizontal: "7" }}>
                           {session.provider !== "" ? session.provider : "Sin datos"}
                        </Text>
                     </View>
                  </View>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>
                           ID de reunión / Código:
                        </Text>
                     </View>
                     <View
                        style={{
                           backgroundColor: colors.primary,
                           width: "50%",
                           justifyContent: "center",
                           padding: "",
                        }}
                     >
                        <Text
                           style={{
                              fontSize: "10",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {session.meetingURL !== "" ? session.meetingURL : "Sin datos"}
                        </Text>
                     </View>
                  </View>
               </View>
               {/*HORAS INICIALES Y HORAS FINAL*/}
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     borderTop: "1",
                     borderColor: "white",
                     justifyContent: "space-between",
                  }}
               >
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>
                           Hora Inicial:
                        </Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "50%", justifyContent: "center" }}>
                        <Text
                           style={{
                              fontSize: "10",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {formattedHourStart}
                        </Text>
                     </View>
                  </View>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>
                           Hora Final:
                        </Text>
                     </View>
                     <View
                        style={{
                           backgroundColor: colors.primary,
                           width: "50%",
                           justifyContent: "center",
                           padding: "",
                        }}
                     >
                        <Text
                           style={{
                              fontSize: "10",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {finishingHour}
                        </Text>
                     </View>
                  </View>
               </View>
               {/*NUMERO DE CONVOCATORIA*/}
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     borderTop: "1",
                     borderColor: "white",
                  }}
               >
                  <View
                     style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        gap: "4",
                     }}
                  >
                     <View style={{ justifyContent: "center", width: "24.90%" }}>
                        <Text style={{ fontSize: "10", fontFamily: "open-sans-bold", textAlign: "right" }}>
                           # de convocatoria:
                        </Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "75.5%", justifyContent: "center" }}>
                        <Text
                           style={{
                              fontSize: "10",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {session.proclamation}
                        </Text>
                     </View>
                  </View>
               </View>
            </View>
            {/*CONVOCADOS A SESION*/}
            <View
               style={{
                  display: "flex",
                  backgroundColor: colors.primary,
                  marginTop: "17",
                  alignItems: "center",
                  paddingVertical: "3",
               }}
            >
               <Text
                  style={{
                     textAlign: "center",
                     color: colorsText.primary,
                     fontSize: "10",
                     fontFamily: "open-sans-bold",
                  }}
               >
                  CONVOCADOS A ASAMBLEA
               </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "5",
                     width: "37.5%",
                     fontFamily: "open-sans-bold",
                     backgroundColor: "#efefef",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                  }}
               >
                  ACCIONISTA
               </Text>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "5",
                     width: "37.5%",
                     fontFamily: "open-sans-bold",
                     backgroundColor: "#efefef",
                     borderLeft: "1",
                     borderBottom: "1",
                     borderColor: "white",
                  }}
               >
                  PORCENTAJE DE ACCIONES
               </Text>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "5",
                     width: "25%",
                     fontFamily: "open-sans-bold",
                     backgroundColor: "#efefef",
                     borderLeft: "1",
                     borderBottom: "1",
                     borderColor: "white",
                  }}
               >
                  DOCUMENTACIÓN
               </Text>
            </View>
            {/*MAPEO DE PARTICIPANTES*/}
            {shareholdersArray.map((shareholder, index) => (
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "8",
                        paddingVertical: "6",
                        width: "37.5%",
                        backgroundColor: "#efefef",
                        borderRight: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {shareholder.firstName + " " + shareholder.lastName}
                  </Text>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "8",
                        paddingVertical: "6",
                        width: "37.5%",
                        backgroundColor: "#efefef",
                        borderLeft: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {((shareholder.totalCapital * 100) / governingBody.totalCompanyCapital).toFixed(2)}%
                  </Text>
                  <View
                     style={{
                        display: "flex",
                        backgroundColor: "#efefef",
                        width: "25%",
                        borderLeft: 1,
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     <Svg style={styles.checkbox}>
                        <Polygon points="57.5,5 57.5,17.5 70,17.5 70,5" stroke="black" strokeWidth={1} />
                        {/*CHECKBOX PALOMEADA*/}
                        {values["officialID" + shareholder._id] && (
                           <>
                              <Polygon points="60,12 62.5,15" stroke="black" strokeWidth={1} />
                              <Polygon points="62.5,15 67,7" stroke="black" strokeWidth={1} />
                           </>
                        )}
                     </Svg>
                  </View>
               </View>
            ))}
            <View
               style={{
                  borderTop: "2",
                  borderColor: colors.primary,
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1",
                  borderBottomColor: "black",
               }}
            >
               <Text
                  style={{
                     textAlign: "right",
                     fontSize: "8",
                     paddingVertical: "5",
                     backgroundColor: colors.secondary,
                     width: "85%",
                     borderRight: "1",
                     borderRightColor: "white",
                     paddingRight: "15",
                     color: colorsText.secondary,
                  }}
               >
                  QUORUM
               </Text>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "8",
                     backgroundColor: "#EFEFEF",
                     paddingVertical: "5",
                     width: "15%",
                  }}
               >
                  {companyPercentageAttendance} %
               </Text>
            </View>
            {/*INVITADOS*/}
            {externalMembers.length > 0 && (
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.secondary,
                     alignItems: "center",
                     paddingVertical: "3",
                     marginTop: "17",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.secondary, fontSize: "12" }}>INVITADOS</Text>
               </View>
            )}
            {/*MAPEO DE INVITADOS*/}
            {externalMembers.map((member) => (
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderRight: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {member?.firstName ? `${member.firstName} ${member.lastName}` : member?.name || member.email}
                  </Text>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderLeft: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {(member?.memberCharge && member?.memberCharge[0]) ||
                        `Usuario Externo - ${member.specialty || "Sin especialidad"}`}
                  </Text>
               </View>
            ))}
            {/*ORDEN DEL DIA*/}
            <View
               style={{
                  display: "flex",
                  marginTop: "17",
                  alignItems: "center",
                  paddingVertical: "3",
                  borderBottom: "3",
               }}
            >
               <Text style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold" }}>ORDEN DEL DÍA</Text>
            </View>
            {/*MAPEO DE ORDENES DEL DIA*/}
            {affairsArray.map((order, index) => {
               return (
                  <View style={{ marginTop: "0.5", borderBottom: "1", borderColor: "white" }}>
                     <View
                        style={{
                           display: "flex",
                           flexDirection: "row",
                           borderBottom: "1",
                           borderColor: "white",
                           gap: "2",
                           alignItems: "center",
                        }}
                     >
                        <Text
                           style={{
                              textAlign: "right",
                              fontSize: "8",
                              paddingVertical: "4.4",
                              paddingRight: "4.5",
                              width: "4%",
                              backgroundColor: "#efefef",
                              fontFamily: "open-sans-bold",
                           }}
                        >
                           {index + 1}
                        </Text>
                        <Text
                           style={{
                              fontSize: "8",
                              paddingVertical: "5.4",
                              width: "45%",
                              backgroundColor: "#efefef",
                              paddingLeft: "4",
                              fontFamily: "open-sans",
                           }}
                        >
                           {order.title}
                        </Text>
                        <Text
                           style={{
                              fontSize: "8",
                              paddingVertical: "5.4",
                              width: "45%",
                              backgroundColor: "#efefef",
                              paddingLeft: "4",
                           }}
                        >
                           {order.description}
                        </Text>
                        <View
                           style={{
                              backgroundColor: "#efefef",
                              width: "6%",
                              borderLeft: 1,
                              borderColor: "white",
                              paddingVertical: "0.45",
                           }}
                        >
                           <Svg style={styles.checkbox}>
                              <Polygon points="8,4 8,16.5 20,16.5 20,4" stroke="black" strokeWidth={1} />
                              {/*CHECKBOX PALOMEADA*/}
                              {values["orderDay-" + order.orderId] && (
                                 <>
                                    <Polygon points="10,12 12.5,14" stroke="black" strokeWidth={1} />
                                    <Polygon points="12.5,14 18,7" stroke="black" strokeWidth={1} />
                                 </>
                              )}
                           </Svg>
                        </View>
                     </View>
                  </View>
               );
            })}

            {/*DELIBERACIONES*/}
            <View style={{ display: "flex", flexDirection: "column", marginTop: "17" }}>
               <Text
                  style={{
                     width: "100%",
                     color: colorsText.secondary,
                     backgroundColor: colors.secondary,
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "3",
                     fontFamily: "open-sans-bold",
                  }}
               >
                  DELIBERACIONES
               </Text>
               <View style={{ border: 1, borderColor: "#EFEFEF" }}>
                  {affairVotes.map((order) => {
                     const linkedVotes = additionalVotes.filter((addVote) => addVote.orderId === order.affair.orderId);
                     return (
                        <View key={order.title} style={{ borderBottom: 1, borderColor: colors.secondary }}>
                           <View
                              style={{
                                 border: 1,
                                 borderColor: "#EFEFEF",
                                 paddingHorizontal: 20,
                                 paddingVertical: 10,
                              }}
                           >
                              <Text style={{ fontSize: "10" }}>{order.affair.description}</Text>
                           </View>
                           <View style={{ display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap" }}>
                              {order.totalVotesUsed > 0 &&
                                 shareholdersArray.map((shareholder) => {
                                    const founuserInVotation = order.users.find(
                                       (user) => user.user === shareholder._id
                                    );
                                    return votationAnswerContainer(
                                       `${shareholder.firstName} ${shareholder.lastName}`,
                                       founuserInVotation.abstention ? "ABS" : founuserInVotation.answer || "PEN"
                                    );
                                 })}
                           </View>
                           {linkedVotes.length > 0 && (
                              <View>
                                 <View
                                    style={{
                                       borderTop: 1,
                                       borderBottom: 1,
                                       borderColor: "#EFEFEF",
                                       paddingHorizontal: 20,
                                       paddingVertical: 5,
                                    }}
                                 >
                                    <Text
                                       style={{
                                          width: "100%",
                                          textAlign: "center",
                                          fontSize: "10",
                                          paddingVertical: "1",
                                          fontFamily: "open-sans-bold",
                                       }}
                                    >
                                       Votos adicionales
                                    </Text>
                                 </View>
                                 {linkedVotes
                                    .filter((addVote) => addVote.orderId)
                                    .map((addVote) => AdditionalVotationsContainer(addVote, true))}
                              </View>
                           )}
                        </View>
                     );
                  })}
                  {additionalVotes
                     .filter((addVote) => !addVote.orderId)
                     .map((addVote) => AdditionalVotationsContainer(addVote, false))}
               </View>
            </View>
            {/*RESOLUCIONES*/}
            <View style={{ marginTop: 17 }} wrap={false}>
               <Text style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold", paddingBottom: 3 }}>
                  RESOLUCIONES
               </Text>
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     ASUNTO
                  </Text>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     DESCRIPCIÓN
                  </Text>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     RESOLUCIÓN
                  </Text>
               </View>

               {sessionResolutions
                  .map((order) => {
                     if (order.resolution.includes("no completada")) return;
                     return (
                        <View style={{ display: "flex", flexDirection: "row" }} wrap={false}>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.title}
                           </Text>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.description}
                           </Text>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.resolution}
                           </Text>
                        </View>
                     );
                  })
                  .filter((v) => v)}
            </View>

            {/*ACUERDOS Y/O COMPROMISOS*/}
            <View style={{ display: "flex", marginTop: "17", alignItems: "center", paddingVertical: "3" }}>
               <Text style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold" }}>
                  ACUERDOS Y/O COMPROMISOS
               </Text>
            </View>
            {/*HEADER DE ACUERDOS Y/O COMPROMISOS*/}
            <View style={{ display: "flex", flexDirection: "row" }}>
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.primary,
                     alignItems: "center",
                     paddingVertical: "3",
                     width: "33%",
                  }}
                  wrap={false}
               >
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>RESPONSABLE</Text>
               </View>
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.primary,
                     alignItems: "center",
                     paddingVertical: "3",
                     width: "33%",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>ACTIVIDAD</Text>
               </View>
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.primary,
                     alignItems: "center",
                     paddingVertical: "3",
                     width: "33%",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                     FECHA COMPROMISO
                  </Text>
               </View>
            </View>
            {/*MAPEO DE ACUERDOS Y/O COMPROMISOS */}
            {values["commitmentsArray"].map((element) => {
               let formattedDate = new Date(element.limitDate).toLocaleDateString();
               const year = formattedDate.split("/")[2];
               if (Number(year) >= 3000) formattedDate = "Indefinido";
               return (
                  <View style={{ display: "flex", flexDirection: "row" }} wrap={false}>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "8" }}>
                           {element.users
                              .map((user) => `${user.firstName || user?.email} ${user.lastName || ""}`)
                              .join(", ")}
                        </Text>
                     </View>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "8" }}>{element.activity}</Text>
                     </View>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "8" }}>
                           {formattedDate === "Invalid Date" ? "Indefinido" : formattedDate}
                        </Text>
                     </View>
                  </View>
               );
            })}
            {/*FIRMA DE ACUERDOS*/}
            <View
               style={{
                  display: "flex",
                  backgroundColor: colors.secondary,
                  fontFamily: "open-sans-bold",
                  alignItems: "center",
                  paddingVertical: "3",
                  marginTop: "17",
               }}
            >
               <Text style={{ textAlign: "center", color: colorsText.secondary, fontSize: "10" }}>
                  FIRMA DE ACUERDOS
               </Text>
            </View>
            {/*HEADERS FIRMA DE ACUERDOS*/}
            <View style={{ display: "flex", flexDirection: "row" }}>
               <View
                  style={{
                     width: "60%",
                     paddingVertical: "6",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                     justifyContent: "center",
                     backgroundColor: colors.primary,
                  }}
               >
                  <Text
                     style={{
                        color: colorsText.primary,
                        textAlign: "center",
                        fontSize: "8",
                        fontFamily: "open-sans-bold",
                        fontWeight: "bold",
                     }}
                  >
                     NOMBRE
                  </Text>
               </View>
               <View
                  style={{
                     width: "40%",
                     paddingVertical: "6",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                     justifyContent: "center",
                     backgroundColor: colors.primary,
                  }}
               >
                  <Text
                     style={{
                        color: colorsText.primary,
                        textAlign: "center",
                        fontSize: "8",
                        fontFamily: "open-sans-bold",
                        fontWeight: "bold",
                     }}
                  >
                     FIRMA
                  </Text>
               </View>
            </View>
            {/*MAPEO DE FIRMAS*/}
            {signsArray.map((signs) => {
               return (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                     <View
                        style={{
                           width: "60%",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                           backgroundColor: "#efefef",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "8" }}>{signs.userName}</Text>
                     </View>
                     <View
                        style={{
                           width: "40%",
                           paddingVertical: "6",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                           backgroundColor: "#efefef",
                        }}
                     >
                        <Image
                           src={signs.sign}
                           style={{
                              marginHorizontal: "10%",
                              maxWidth: "auto",
                              maxHeight: "60px",
                              objectFit: "contain",
                           }}
                        />
                     </View>
                  </View>
               );
            })}
            {/*OTROS ASUNTOS*/}
            {otherSubjectsArray.length > 0 && (
               <View
                  style={{
                     display: "flex",
                     marginTop: "17",
                     alignItems: "center",
                     paddingVertical: "3",
                     borderBottom: "3",
                     borderColor: colors.secondary,
                  }}
               >
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        fontWeight: "bold",
                     }}
                  >
                     OTROS ASUNTOS
                  </Text>
               </View>
            )}
            {otherSubjectsArray.map((otherSubject, index) => {
               if (otherSubject === "") return;
               return (
                  <View
                     wrap={false}
                     style={{
                        display: "flex",
                        flexDirection: "row",
                        borderBottom: "1",
                        borderColor: "white",
                        gap: "2",
                        alignItems: "center",
                     }}
                  >
                     <Text
                        style={{
                           textAlign: "right",
                           fontSize: "10",
                           paddingVertical: "4",
                           paddingRight: "5",
                           width: "8%",
                           backgroundColor: "#efefef",
                           fontFamily: "open-sans-bold",
                           fontWeight: "bold",
                        }}
                     >
                        {index + 1}.
                     </Text>
                     <Text
                        style={{
                           fontSize: "8",
                           paddingVertical: "6",
                           width: "92%",
                           backgroundColor: "#efefef",
                           paddingLeft: "10",
                        }}
                     >
                        {otherSubject}
                     </Text>
                  </View>
               );
            })}
            {/*NOTAS*/}
            {values.notes && (
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.primary,
                     alignItems: "center",
                     paddingVertical: "3",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>NOTAS</Text>
               </View>
            )}
            {values.notes && (
               <View style={{ minHeight: "100", backgroundColor: "#efefef" }}>
                  <Text style={{ fontSize: "10", margin: "4" }}>{values.notes || ""}</Text>
               </View>
            )}
            {/*COMENTARIOS*/}
            {values.comment.length > 0 && (
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.primary,
                     alignItems: "center",
                     paddingVertical: "3",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                     COMENTARIOS CLAVE DEL PRESIDENTE / VICEPRESIDENTE
                  </Text>
               </View>
            )}
            {values.comment.length > 0 && (
               <View style={{ minHeight: "100", backgroundColor: "#efefef" }}>
                  {values.comment.map((c, i) => (
                     <Text style={{ fontSize: "12", margin: "4" }}>{`${i + 1}. ${c}`}</Text>
                  ))}
               </View>
            )}
            {/*LINK DE UBICACIÓN DE ARCHIVO DE VIDEO*/}
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: colors.primary,
                  alignItems: "center",
                  marginTop: 17,
               }}
            >
               <View style={{ width: "40%", marginRight: 3 }}>
                  <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                     LINK DE GRABACIÓN:
                  </Text>
               </View>
               <View style={{ marginLeft: 115 }}>
                  <Svg style={{ height: "24", width: "100%" }}>
                     <Polygon points="8,6 8,18.5 20,18.5 20,6" stroke={colorsText.primary} strokeWidth={1} />
                     {/*CHECKBOX PALOMEADA*/}
                     {values.meetingUrl && (
                        <>
                           <Polygon points="10,14 12.5,16" stroke={colorsText.primary} strokeWidth={1} />
                           <Polygon points="12.5,16 18,9" stroke={colorsText.primary} strokeWidth={1} />
                        </>
                     )}
                  </Svg>
               </View>
            </View>
            {/*FECHA PROXIMA REUNION*/}
            {/* <View style={{ marginTop: "17", border: 1, display: "flex", flexDirection: "row" }}>
               <View style={{ width: "40%", paddingVertical: "5" }}>
                  <Text style={{ fontSize: "8", textAlign: "center" }}>FECHA DE LA PRÓXIMA REUNIÓN:</Text>
               </View>
               <View
                  style={{
                     width: "60%",
                     paddingVertical: "5",
                     borderLeft: "1",
                     paddingLeft: "6",
                     backgroundColor: colors.secondary,
                  }}
               >
                  <Text style={{ fontSize: "8", color: colorsText.secondary }}>{values.nextDate}</Text>
               </View>
            </View> */}
            {session.completed === false && cancelled !== true ? (
               <Text
                  style={{
                     position: "absolute",
                     top: "50%",
                     left: "15%",
                     fontSize: 45,
                     color: "red",
                     border: 6,
                     borderColor: "red",
                     borderRadius: "15",
                     fontWeight: 600,
                     padding: "15",
                     textAlign: "center",
                     transform: "rotate(-45deg)",
                  }}
                  fixed
               >
                  SESIÓN INVÁLIDA
               </Text>
            ) : (
               cancelled === true && (
                  <Text
                     style={{
                        position: "absolute",
                        top: "5%",
                        left: "35%",
                        fontSize: 20,
                        color: "red",
                        border: 3,
                        borderColor: "red",
                        borderRadius: "15",
                        fontWeight: 600,
                        padding: "7",
                        paddingLeft: "10",
                        paddingTop: "12.5",
                        textAlign: "center",
                     }}
                     fixed
                  >
                     SESIÓN CANCELADA
                  </Text>
               )
            )}
            <View style={styles.pageNumber} fixed>
               <Text
                  style={{ fontSize: "12px", textAlign: "center" }}
                  render={({ pageNumber }) => `${pageNumber}`}
                  fixed
               />
            </View>
         </Page>
      </Document>
   );
};

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
   },
   checkbox: {
      height: "22",
      border: 1,
      borderColor: "#EFEFEF",
      width: "100%",
   },
   pageNumber: {
      position: "absolute",
      right: 30,
      bottom: 30,
   },
   //   box: {
   //      stroke: "black",
   //      strokeWidth: 1,
   //   },
   //   check1: {
   //      fill: "none",
   //      stroke: "black",
   //   },
   //   check2: {
   //      fill: "none",
   //      stroke: "black",
   //   },
   //   box2: {
   //      stroke: "black",
   //      strokeWidth: 1,
   //   },
   //   check12: {
   //      fill: "none",
   //      stroke: "black",
   //   },
   //   check22: {
   //      fill: "none",
   //      stroke: "black",
   //   },
});
