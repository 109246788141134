import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useRef } from "react";
import { UserContext } from "../../../../context/userContext";
import { SessionsGraph } from "./SessionsGraph";
import { CommitmentsGraph } from "./CommitmentsGraph";
import { YearlyPerformanceGraph } from "./YearlyPerformanceGraph";
import { getDateFromISODate } from "../../../../const/globalConst";
import { useGetGraphInformationByGovernance } from "../../../../hooks/gob-corp/governmentBody/useGetGraphicsInformation";

export const CouncilGraphComponent = ({ governance, chartRefs }) => {
   const { sessions, isLoading, commitments } = useGetGraphInformationByGovernance(governance._id);
   const { user } = useContext(UserContext);
   const date = new Date().getFullYear();
   const now = new Date(date + "-01-01T00:00:00.000Z");

   const commitmentsAc = useMemo(() => {
      return commitments.filter((commitment) => commitment.status === "COMPLETE").length;
   }, [commitments]);

   const totalCommitments = useMemo(() => {
      return commitments.filter((commitment) => commitment.users.includes(user.id)).length;
   }, [commitments, user.id]);

   const yearlyPerformanceRef = useRef(null);
   const sessionsGraphRef = useRef(null);
   const commitmentsGraphRef = useRef(null);

   useEffect(() => {
      const setNewChartData = () => {
         if (!chartRefs.some((ref) => ref.governance === governance._id)) {
            chartRefs.push({
               governance: governance._id,
               yearlyPerformanceRef,
               sessionsGraphRef,
               commitmentsGraphRef,
               sessionData: sessionsGraphRef.current.data.datasets[0].data.slice(0, 2),
               commitmentsData: commitmentsGraphRef.current.data.datasets[0].data.slice(0, 5),
               compliancePercentage: `${
                  commitmentsAc / totalCommitments ? ((commitmentsAc / totalCommitments) * 100).toFixed(2) : 0
               }%`,
               assignedCommitments: totalCommitments,
               updatedAt: getDateFromISODate(new Date()),
            });
         }
      };

      if (yearlyPerformanceRef.current && sessionsGraphRef.current && commitmentsGraphRef.current)
         return setNewChartData();
   }, [yearlyPerformanceRef.current, sessionsGraphRef.current, commitmentsGraphRef.current]);

   return (
      <Stack
         sx={{
            p: 2,
            bgcolor: "white",
            borderRadius: 1,
            border: 1,
            borderColor: "#D8D8D8",
         }}
      >
         <Box sx={{ mx: 2, display: "flex", justifyContent: "flex-start" }}>
            <Box sx={{ color: "#162c44", fontWeight: 600, px: 1 }}>
               <Typography variant="body2">{governance.title}</Typography>
            </Box>
         </Box>
         <Divider />
         <Box id={"capture"} sx={{ display: "flex", py: 4, columnGap: 6 }}>
            <YearlyPerformanceGraph
               sessions={sessions}
               commitments={totalCommitments}
               chartRef={yearlyPerformanceRef}
            />
            <SessionsGraph sessions={sessions} user={user.id} isLoading={isLoading} chartRef={sessionsGraphRef} />
            <CommitmentsGraph
               commitmentCount={!isLoading && commitments.length > 0 ? commitments.length : 0}
               filteredCommitments={commitments}
               isLoading={isLoading}
               chartRef={commitmentsGraphRef}
            />
         </Box>

         <Divider />
         <Stack sx={{ p: 2 }}>
            <Grid container>
               <Grid item container xs={8} spacing={1}>
                  <Grid item xs={6}>
                     <Stack direction={"row"} columnGap={2}>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                           Última actualización:
                        </Typography>
                        <Typography variant="body2">{getDateFromISODate(new Date())}</Typography>
                     </Stack>
                  </Grid>
                  <Grid item xs={6}>
                     <Stack direction={"row"} columnGap={2}>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                           Acuerdos y compromisos asignados:
                        </Typography>
                        <Typography variant="body2">{totalCommitments}</Typography>
                     </Stack>
                  </Grid>
                  <Grid item xs={6}>
                     <Stack direction={"row"} columnGap={2}>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                           Fecha de inicio:
                        </Typography>
                        <Typography variant="body2">{getDateFromISODate(now)}</Typography>
                     </Stack>
                  </Grid>
                  <Grid item xs={6}>
                     <Stack direction={"row"} columnGap={2}>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                           % cumplimiento:
                        </Typography>
                        <Typography
                           sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                           variant="body2"
                        >
                           {commitmentsAc / totalCommitments
                              ? ((commitmentsAc / totalCommitments) * 100).toFixed(2)
                              : 0}
                           %
                        </Typography>
                     </Stack>
                  </Grid>
               </Grid>
               {/* <Grid item xs={4} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                  <Button variant="contained" fullWidth sx={{ maxWidth: 250 }}>
                     Carpeta de reportes
                  </Button>
               </Grid> */}
            </Grid>
         </Stack>
      </Stack>
   );
};
