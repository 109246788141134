import { Box, Tab, Tabs, Typography } from "@mui/material";
import { PlatformInfoComponent } from "../../components/MiLecosy/PlatformInfoComponent";
import { useContext, useMemo, useState } from "react";
import { TabPanel } from "./ClientInfoScreenNew";
import ErrorIcon from "@mui/icons-material/Error";
import { UserByModuleComponent } from "../../components/MiLecosy/UserByModuleComponent";
import { ModuleInfoGC } from "../../components/MiLecosy/ModuleInfoGC";
import { GoverningBodiesComponent } from "../../components/MiLecosy/GoverningBodiesComponent";
import { UserContext } from "../../context/userContext";

interface ServiceInfoScreenProps {
   company?: any;
   services: any[];
}
export function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}
interface document {
   name: string;
   size: number;
}

export const ServiceInfoScreen = (props: ServiceInfoScreenProps) => {
   const [value, setValue] = useState(0);
   const [document, setDocument] = useState<document>(null);
   const [shareholderBodyId, setShareholderBodyId] = useState("");
   const [bodiesSeed, setBodiesSeed] = useState(0);
   const [corporateDataCheck, setCorporateDataCheck] = useState();
   const { isImplementationUser, user } = useContext(UserContext);

   const servicesOrder = [
      "Canal de denuncias",
      "Programa de cumplimiento",
      "Gobierno corporativo",
      "Beneficiario controlador",
   ];

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const filteredAndSortedServices = useMemo(() => {
      if (!props.services || !user.modules) return [];
      const filteredServices = props.services.filter((service) => {
         if (
            isImplementationUser() &&
            (service.serviceId.service === "Gobierno corporativo" ||
               service.serviceId.service === "Beneficiario controlador")
         )
            return true;
         return true;
      });

      return filteredServices.sort((a, b) => {
         const aKey = a.serviceId.service;
         const bKey = b.serviceId.service;
         return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
      });
   }, [props.services, isImplementationUser, user]);

   function renderServiceContent(service) {
      switch (service.serviceId.service) {
         case "Canal de denuncias":
            return (
               <>
                  <PlatformInfoComponent company={props.company || null} />
               </>
            );

         case "Gobierno corporativo":
            return (
               <>
                  <ModuleInfoGC
                     document={document || null}
                     shareholdersBodyId={shareholderBodyId || ""}
                     setBodiesSeed={setBodiesSeed}
                     bodiesSeed={bodiesSeed}
                     setCorporateDataCheck={setCorporateDataCheck}
                  />
                  {corporateDataCheck && (
                     <GoverningBodiesComponent
                        setDocument={setDocument}
                        setShareholderBodyId={setShareholderBodyId}
                        bodiesSeed={bodiesSeed}
                     />
                  )}
               </>
            );
         case "Beneficiario controlador":
            return (
               <>
                  <ModuleInfoGC
                     document={document || null}
                     shareholdersBodyId={shareholderBodyId || ""}
                     setBodiesSeed={setBodiesSeed}
                     bodiesSeed={bodiesSeed}
                     setCorporateDataCheck={setCorporateDataCheck}
                  />
                  {corporateDataCheck && (
                     <GoverningBodiesComponent
                        setDocument={setDocument}
                        setShareholderBodyId={setShareholderBodyId}
                        bodiesSeed={bodiesSeed}
                     />
                  )}
               </>
            );

         default:
            return (
               <Box
                  display="flex"
                  sx={{
                     borderBottomRightRadius: 5,
                     borderBottomLeftRadius: 5,
                     p: 4,
                     justifyContent: "center",
                     alignItems: "center",
                     columnGap: 2,
                  }}
                  boxShadow={2}
                  bgcolor="white"
               >
                  <Typography>No existe información acerca de este servicio</Typography>
                  <ErrorIcon sx={{ width: "35px", height: "35px", color: "#162c44" }} />
               </Box>
            );
      }
   }

   return (
      <>
         {props.services.length > 0 ? (
            <>
               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderColor: "divider", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} value={value} onChange={handleChange}>
                     {filteredAndSortedServices.map((service, index) => (
                        <Tab
                           key={index}
                           sx={{ fontSize: 14, fontWeight: 600 }}
                           label={service.serviceId.service}
                           {...a11yProps(index)}
                        />
                     ))}
                  </Tabs>
               </Box>
               {filteredAndSortedServices.map((service, index) => (
                  <TabPanel index={index} key={index} value={value}>
                     <Box mb={2} display={"flex"} flexDirection={"column"} rowGap={2}>
                        {renderServiceContent(service)}
                        <UserByModuleComponent company={props.company} serviceName={service} />
                     </Box>
                  </TabPanel>
               ))}
            </>
         ) : (
            <Box
               display="flex"
               sx={{
                  borderRadius: 1,
                  p: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: 2,
               }}
               boxShadow={2}
               bgcolor="white"
            >
               <Typography>No se ha contratado ningún servicio</Typography>
               <ErrorIcon sx={{ width: "35px", height: "35px", color: "#162c44" }} />
            </Box>
         )}
      </>
   );
};
