import { CircularProgress, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState, useContext, useCallback, useMemo } from "react";
import { DoughnutChart } from "../components/charts/doughnutChart";
import { SingleLineChart } from "../components/charts/LineChart";
import { CompaniesCarousel } from "../components/CompaniesCarousel";
import { getDateFromISODate, getMonthWord, toISOString } from "../const/globalConst";
import GetFilteredComplaints from "../hooks/complaints/getFilteredComplaints";
import { ComplaintContext } from "../context/complaintContext";
import UseCreateCharts from "../hooks/useCreateCharts";
import { getGBCommitmentsByCompany } from "../lib/gobCorpBEClient";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { UserContext } from "../context/userContext";
import { GovernanceContext } from "../context/governanceContext/governanceContext";

export const DashboardScreen = () => {
   const { verifyAccess } = useContext(UserContext);
   const cdPermission = verifyAccess(["Firmas", "Difusión-Reportes-Banco", "Denuncias externas"]);
   const GCaccess = verifyAccess("Gobierno corporativo", true);
   const CDaccess = verifyAccess("Canal de denuncias", true) && cdPermission;
   return (
      <>
         {CDaccess && <CDDashboard />}
         {GCaccess && <GCDashboard />}
      </>
   );
};

const CDDashboard = () => {
   const { loadingComplaints, complaints } = useContext(ComplaintContext);
   const filteredComplaints = useMemo(() => GetFilteredComplaints(complaints, "todos"), [complaints]);

   const totalComplaintRef: any = useRef();
   const [charts, setCharts] = useState({
      total: {
         title: "Denuncias",
         chart: <Box />,
         colors: ["#8a95a0", "#162c44"],
      },
      process: {
         title: "Módulo canal de denuncias",
         chart: <Box />,
      },
   });

   function getMonthNumber(mNumber) {
      if (mNumber <= 0) return 12 + mNumber;
      return mNumber;
   }

   const getDates = useCallback(() => {
      const dates: any = [];
      for (let i = 5; i >= 0; i--) {
         const helperDate = toISOString(new Date()).substring(5, 7);
         const month = getMonthNumber(parseInt(helperDate, 10) - i);
         dates.push(month);
      }
      return dates;
   }, []);

   function getEquivalentDates(date) {
      const getDate = date.substring(3, 5);
      let numb = getDate.match(/\d/g);
      numb = numb.join("");
      return parseInt(numb, 10);
   }

   function complaintCount(count) {
      let isNotNull = false;
      count.forEach((e) => {
         if (e !== 0) isNotNull = true;
      });
      return isNotNull;
   }

   useEffect(() => {
      const fetchComplaints = async () => {
         try {
            getCharts(filteredComplaints, getDates());
         } catch (error) {
            setCharts({
               ...charts,
               total: {
                  title: "Denuncias",
                  chart: errorHandlerBox("Error al cargar las denuncias"),
                  colors: charts.total.colors,
               },
               process: {
                  title: "Módulo canal de denuncias",
                  chart: errorHandlerBox("Error al cargar las denuncias"),
               },
            });
         }
      };
      fetchComplaints();
      // eslint-disable-next-line
   }, [filteredComplaints]);

   const getCharts = (eValues: any, months) => {
      const count: number[] = [0, 0];
      const count2: number[] = [0, 0, 0, 0, 0, 0];
      const stringMonths: string[] = [];
      eValues.forEach((e) => {
         if (e.complaint.status === "resuelto") count[0]++;
         else if (e.complaint.status === "en proceso" || e.complaint.status === "en valoración") count[1]++;
         months.forEach((month, index) => {
            if (getEquivalentDates(getDateFromISODate(e.complaint.createdAt)) === month) {
               count2[index]++;
            }
         });
      });
      months.forEach((month: number) => {
         stringMonths.push(getMonthWord(month.toString()).substring(0, 3));
      });
      const processCount = complaintCount(count);
      const interpositionCount = complaintCount(count2);
      setCharts({
         ...charts,
         total: {
            title: "Denuncias",
            chart:
               processCount && filteredComplaints.length > 0 ? (
                  <DoughnutChart
                     scores={count}
                     labels={["Resueltas", "En proceso"]}
                     colors={charts.total.colors}
                     chartRef={totalComplaintRef}
                     textCenterProp={textCenter({
                        size: "24",
                        text: `${((count[1] * 100) / (count[0] + count[1])).toFixed(2)}%`,
                        color: "#000000",
                     })}
                  />
               ) : (
                  errorHandlerBox("No existen denuncias en proceso y/o resueltas")
               ),
            colors: charts.total.colors,
         },
         process: {
            title: "Módulo canal de denuncias",
            chart:
               interpositionCount && filteredComplaints.length > 0 ? (
                  <SingleLineChart scores={count2} labels={stringMonths} labelNames={count2} pointColor={"red"} />
               ) : (
                  errorHandlerBox("No existen denuncias interpuestas")
               ),
         },
      });
   };
   return (
      <>
         <Typography fontWeight={600} fontSize={22} sx={{ width: "90%", m: "auto", my: 4 }}>
            Canal de denuncias
         </Typography>

         <Box
            sx={{
               display: "flex",
               flexWrap: "wrap",
               alignItems: "center",
               justifyContent: "space-evenly",
            }}
         >
            {!loadingComplaints ? (
               <>
                  <Box sx={{ bgcolor: "#ffffff", boxShadow: 2, borderRadius: 2.5 }}>
                     <Typography textAlign={"center"} sx={{ mt: 2, color: "#162c44", fontWeight: "bold" }}>
                        {charts.process.title}
                     </Typography>
                     <Box
                        width={380}
                        height={250}
                        sx={{
                           p: 2,
                           display: "flex",
                        }}
                     >
                        {charts.process.chart}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "row",
                           border: 1,
                           borderColor: "#EEEEEE",
                           p: 1,
                           m: 2,
                        }}
                     >
                        <Box sx={{ flex: 1, display: "flex", alignItems: "center", px: 2 }}>
                           <Box sx={{ width: "15px", height: "15px", bgcolor: "#162c44", mx: 1 }} />
                           <Typography>Denuncias interpuestas</Typography>
                        </Box>
                     </Box>
                  </Box>
                  <Box sx={{ bgcolor: "#ffffff", boxShadow: 2, borderRadius: 2.5 }}>
                     <Typography textAlign={"center"} sx={{ mt: 2, color: "#162c44", fontWeight: "bold" }}>
                        {charts.total.title}
                     </Typography>
                     <Box
                        width={300}
                        height={250}
                        sx={{
                           p: 2,
                           display: "flex",
                           mx: 8,
                        }}
                     >
                        {charts.total.chart}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "row",
                           m: 2,
                        }}
                     >
                        <Box
                           sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                              border: 1,
                              borderRadius: 1,
                              borderColor: "#EEEEEE",
                           }}
                        >
                           <Box sx={{ width: "15px", height: "15px", bgcolor: "#8a95a0", mx: 1 }} />
                           <Typography>Resueltos</Typography>
                        </Box>
                        <Box
                           sx={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                              border: 1,
                              borderRadius: 1,
                              borderColor: "#EEEEEE",
                           }}
                        >
                           <Box sx={{ width: "15px", height: "15px", bgcolor: "#162c44", mx: 1 }} />
                           <Typography>En proceso</Typography>
                        </Box>
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        my: { xs: 5, lg: 0 },
                        bgcolor: "#ffffff",
                        width: 220,
                        height: "53.75vh",
                        maxHeight: "53.75vh",
                        borderRadius: 2.5,
                        boxShadow: 2,
                        // overflow: "hidden",
                     }}
                  >
                     <Typography textAlign={"center"} sx={{ pt: 2, pb: 1, fontWeight: "bold" }}>
                        Empresas
                     </Typography>
                     <CompaniesCarousel filterName="" selectedCompany={(_e) => {}} orientation={true} />
                  </Box>
               </>
            ) : (
               <Box height={"50%"}>
                  <CircularProgress size={50} />
               </Box>
            )}
         </Box>
      </>
   );
};

const GCDashboard = () => {
   const { roles, user, companies, verifyAccess } = useContext(UserContext);
   const { consultiveGroup, consultiveGroupCompanies } = useContext(GovernanceContext);
   const GCaccess = verifyAccess("Gobierno corporativo", true);
   const [isLoadingGc, setIsLoadingGc] = useState(true);
   const [completedPercentage, setCompletedPercentage] = useState<number>(0);
   const [companyToCheck, setCompanyToCheck] = useState("");
   const [sessionByCompay, setSessionByCompay] = useState([]);
   const [isLoadingCommitments, setIsLoadingCommitments] = useState(false);
   const [formatedCompanies, setFormatedCompanies] = useState([]);
   const [fullArrayGraph, setFullArrayGraph] = useState([]);

   useEffect(() => {
      const getCommitmentsData = async () => {
         setIsLoadingGc(true);
         let privilegedUser = false;
         if (
            roles.includes("Usuario de implementación") ||
            roles.includes("N1") ||
            roles.includes("N2") ||
            roles.includes("Coordinador de gobierno corporativo")
         )
            privilegedUser = true;

         let data = [];
         if (companies.length === 0) {
            const arrayOfGoverns = await getGBCommitmentsByCompany(companies[0]._id, user.id, privilegedUser);
            if (!arrayOfGoverns) return;
            for (const body of arrayOfGoverns) if (body.sessionsData.length > 0) data.push(body);
            setSessionByCompay(data);
         } else {
            if (companyToCheck !== "") {
               const arrayOfGoverns = await getGBCommitmentsByCompany(companyToCheck, user.id, privilegedUser);
               for (const body of arrayOfGoverns) if (body.sessionsData.length > 0) data.push(body);
               setSessionByCompay(data);
            } else setSessionByCompay([]);
         }
         setIsLoadingGc(false);
      };
      if (user.id && roles.length > 0) getCommitmentsData();
   }, [user, GCaccess, companyToCheck, companies, consultiveGroupCompanies]);

   useEffect(() => {
      const processFormated = () => {
         const companiesArray = companies;
         let allCompanies = [];
         if (consultiveGroup) {
            allCompanies.push({ _id: consultiveGroup._id, comercialName: consultiveGroup.name });
         }
         for (const company of companiesArray) {
            let data = (({ _id }) => ({ _id }))(company);
            let title = (({ comercialName }) => ({ comercialName }))(company.person_details);
            allCompanies.push({ ...data, ...title });
         }
         setCompanyToCheck(allCompanies[0]?._id);
         setFormatedCompanies(allCompanies);
      };
      if (companies.length > 1) processFormated();
   }, [user, consultiveGroup]);

   const filteredCommitments = useMemo(() => {
      if (sessionByCompay.length > 0) {
         setIsLoadingCommitments(true);
         let arrayCommitments = [];
         let filteredCommitmentsByGoverns = [];
         for (const body of sessionByCompay) {
            let bodyTitle = body.bodyData.title;
            let completedCommitments: any = [];
            let expiredCommitments: any = [];
            body.sessionsData.forEach((session) => {
               arrayCommitments = arrayCommitments.concat(session.commitments);
            });
            completedCommitments = {
               completedCommitments: arrayCommitments.filter((commitment) => commitment.status === "COMPLETE"),
            };
            expiredCommitments = {
               expiredCommitments: arrayCommitments.filter((commitment) => commitment.status === "EXPIRED"),
            };
            filteredCommitmentsByGoverns.push({
               bodyTitle,
               arrayCommitments,
               ...completedCommitments,
               ...expiredCommitments,
            });
         }

         let completedTotal = 0;
         let totalTotal = 0;
         let fullArray = [];
         for (const govern of filteredCommitmentsByGoverns) {
            completedTotal = completedTotal + govern.completedCommitments.length;
            totalTotal = totalTotal + govern.completedCommitments.length + govern.expiredCommitments.length;
            const percentage = govern.completedCommitments?.length > 0 ? (completedTotal / totalTotal) * 100 : 0;
            const percentageNormalized = percentage / 100;
            fullArray.push(percentageNormalized);
         }
         setFullArrayGraph(fullArray);
         const finalTotalCompleted = (completedTotal / totalTotal) * 100;
         setCompletedPercentage(finalTotalCompleted);
         setIsLoadingCommitments(false);
         return filteredCommitmentsByGoverns;
      } else {
         setFullArrayGraph([]);
         setCompletedPercentage(0);
         setIsLoadingCommitments(false);
         return [];
      }
   }, [sessionByCompay]);

   const handleChange = (event: SelectChangeEvent) => {
      setCompanyToCheck(event.target.value);
   };

   return (
      <>
         <Typography fontWeight={600} fontSize={22} sx={{ width: "90%", m: "auto", my: 4 }}>
            Gobierno corporativo
         </Typography>
         <Box
            sx={{
               display: "flex",
               flexWrap: "wrap",
               flexDirection: "column",
               bgcolor: "whitesmoke",
               my: 4,
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
               }}
            >
               <Box
                  sx={{
                     bgcolor: "white",
                     display: "flex",
                     flexDirection: "column",
                     borderRadius: 2.5,
                     boxShadow: 2,
                  }}
               >
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        px: 3,
                        pt: 2,
                        alignItems: "center",
                     }}
                  >
                     <Typography fontWeight={600} fontSize={18}>
                        Efectividad de acuerdos y compromisos
                     </Typography>
                     {companies.length > 1 && (
                        <Select value={companyToCheck} onChange={handleChange} size="small" sx={{ width: 150 }}>
                           {formatedCompanies.map((c, i) => (
                              <MenuItem key={i} value={c._id}>
                                 {c.comercialName}
                              </MenuItem>
                           ))}
                        </Select>
                     )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ height: "80%", width: 800, pr: 5 }}>
                     {!isLoadingCommitments && !isLoadingGc ? (
                        UseCreateCharts({
                           percentage: true,
                           barThickness: 30,
                           type: "bar",
                           chartRef: undefined,
                           colors: ["#162c44", "#DCECFBBF"],
                           labels:
                              filteredCommitments.length > 0
                                 ? filteredCommitments.map((govern) => govern.bodyTitle)
                                 : ["Sin órganos"],
                           labelNames: ["Completado"],
                           scores: filteredCommitments.length > 0 ? [fullArrayGraph] : [],
                           gridColor: "#EEEE",
                           maxY: 1,
                        })
                     ) : (
                        <Box height={"100%"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                           <CircularProgress size={50} />
                        </Box>
                     )}
                  </Box>
               </Box>
               <Stack
                  sx={{
                     bgcolor: "white",
                     width: "20%",
                     height: "416px",
                     borderRadius: 5,
                     boxShadow: 2,
                     alignItems: "center",
                  }}
               >
                  <Stack spacing={3} sx={{ m: "auto" }}>
                     <Typography fontWeight={600} sx={{ px: 4, fontSize: 18, textAlign: "center" }}>
                        Cumplimiento actual
                     </Typography>
                     <Typography fontSize={12} sx={{ textAlign: "center" }}>
                        ÚLTIMA ACT. <strong>{getDateFromISODate(new Date())}</strong>
                     </Typography>
                     {!isLoadingCommitments && !isLoadingGc ? (
                        <Box>
                           {UseCreateCharts({
                              type: "doughnut",
                              scores: [
                                 filteredCommitments.reduce((sum, iteration) => {
                                    return sum + iteration.completedCommitments.length;
                                 }, 0),
                                 filteredCommitments.reduce((sum, iteration) => {
                                    return sum + iteration.expiredCommitments.length;
                                 }, 0),
                              ],
                              labels: ["Completadas", "Expiradas"],
                              chartRef: undefined,
                              colors: ["#162c44", "#DCECFB"],
                              cutout: 50,
                              textCenterProp: textCenter({
                                 size: "25",
                                 text: `${Math.floor(completedPercentage)}%`,
                                 color: "#000000",
                              }),
                           })}
                        </Box>
                     ) : (
                        <Box
                           height={"100%"}
                           sx={{ display: "flex", justifyContent: "center", alignItems: "center", py: 6 }}
                        >
                           <CircularProgress size={50} />
                        </Box>
                     )}
                     <Typography sx={{ px: 4, fontSize: 12, textAlign: "center" }}>
                        El porcentaje de cumplimiento se obtendrá, tomando como 100% el número de acuerdos y compromisos
                        establecidos
                     </Typography>
                  </Stack>
               </Stack>
            </Box>
         </Box>
      </>
   );
};

const textCenter = (secondString: { size; text; color? }) => {
   return {
      id: "textCenter",
      afterDraw(chart, args, pluginOptiona) {
         const { ctx } = chart;
         ctx.save();
         ctx.textAlign = "center";
         ctx.textBaseline = "middle";
         ctx.font = `bolder ${secondString.size ? secondString.size : "25"}px Open Sans`;
         ctx.fillStyle = secondString ? secondString.color : "#162c44";
         ctx.fillText(secondString.text, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
      },
   };
};

const errorHandlerBox = (message) => {
   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
         }}
      >
         <Typography textAlign={"center"} sx={{ color: "#162c44", fontWeight: "bold" }}>
            {message}
         </Typography>
      </Box>
   );
};
