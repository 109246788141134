import { Box, Button, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Form, Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import { createVoteQuestion } from "../../../lib/validations/inputSchemas";
import { CreateVotes, UpdateVotes, updateVotesVotation } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import VotationAnswerOptions from "./SubComponents/sessionVotationComponents/VotationAnswerOptions";
import { SnackBarContext } from "../../../context/snackBarContext";
import _ from "lodash";

export const CreateVoteModal = ({ votationOnCourse, setVotationOnCourse, setOpenModal }) => {
   const { mutate } = CreateVotes(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { session, socket, membersWithCharge, state, dispatch } = useContext(GovernanceSessionContext);
   const [votationAnswers, setVotationAnswers] = useState<string[]>([]);
   const membersWithVotes = membersWithCharge.filter((member) => {
      return !member.memberCharge.every((charge) => charge.toLowerCase().includes("coordinador"));
   });

   const { mutate: CancelVote } = UpdateVotes();

   const createVoteFunction = async (values) => {
      mutate(values, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            dispatch({ type: "setActiveVote", activeVote: data });
            socket.emit("send-votation", { data, sessionId: session._id });
            setVotationOnCourse(true);
         },
      });
   };

   const handleSubmit = async (values) => {
      if (!state.vote) {
         if (votationAnswers.length < 2 || votationAnswers.filter((answer) => answer.trim().length !== 0).length < 2)
            return showSnackBar("La votación debe contener al menos dos respuestas", true);
         if (_.uniq(votationAnswers).length !== votationAnswers.length)
            return showSnackBar("Existen dos o más respuestas iguales", true);
         const VotationUsersRegistry = session.usersRegistry
            .map((user) => {
               const foundOnlineUser = membersWithVotes.find((voteUser) => voteUser._id === user.user);
               if (foundOnlineUser)
                  return {
                     userId: foundOnlineUser._id,
                     userName: `${foundOnlineUser.firstName} ${foundOnlineUser.lastName}`,
                     avaliableVotes: 1,
                  };
            })
            .filter((v) => v);
         createVoteFunction({
            ...values,
            session: session._id,
            answers: votationAnswers,
            votes: VotationUsersRegistry,
         });
      } else {
         const foundVote = state.additionalVotes.find((addVote) => addVote._id === state.vote._id);
         const additionalVoteResolution = await updateVotesVotation(foundVote);
         socket.emit("end-vote", { sessionId: session._id, addVote: additionalVoteResolution });
         setVotationOnCourse(false);
      }
   };

   const handleCancelVotation = () => {
      if (!state.vote) {
         return setOpenModal(false);
      }
      CancelVote(
         { votationId: state.vote._id, voteData: { canceled: true } },
         {
            onError: (error) => {
               console.error(error);
            },
            onSuccess: (data) => {
               socket.emit("cancel-vote", { sessionId: session._id, additionalVote: data, isAffairVote: false });
            },
         }
      );
   };

   const handleActiveVotationState = useMemo(() => {
      if (state.vote === null || state.vote.affair) return null;
      const votationCounts = {
         nullVote: 0,
         pending: 0,
      };
      for (const vote of state.vote.votes) {
         if (vote.abstention) {
            votationCounts["nullVote"] += 1;
            continue;
         }
         if (!vote.answer) {
            votationCounts["pending"] += 1;
            continue;
         }
         if (!votationCounts[vote.answer]) votationCounts[vote.answer] = 1;
         else votationCounts[vote.answer] += 1;
      }
      return votationCounts;
   }, [state]);

   return (
      <Formik
         initialValues={{ title: state.vote ? state.vote.title : "" }}
         validationSchema={createVoteQuestion}
         onSubmit={handleSubmit}
      >
         <Form>
            <Stack spacing={1.5}>
               <Stack spacing={1}>
                  <Typography>Título de la pregunta</Typography>
                  <InputTextField
                     id={"title"}
                     name={"title"}
                     type={"text"}
                     placeholder="Descripción"
                     size={"small"}
                     sx={{ bgcolor: "#F5F5F5" }}
                     multiline
                     maxRows={3}
                  />
               </Stack>
               {votationOnCourse || state.vote ? (
                  state.vote && (
                     <Stack spacing={1}>
                        <Typography>Respuestas:</Typography>
                        <Stack spacing={0.5}>
                           {state.vote.answers.map((answer) => {
                              return (
                                 <Box sx={{ display: "flex", gap: 1, alignItems: "center" }} key={answer}>
                                    <Typography>{`${answer}:`} </Typography>
                                    <Typography>{handleActiveVotationState[answer] || 0}</Typography>
                                 </Box>
                              );
                           })}
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: 600 }}>Se abstuvo a votar: </Typography>
                              <Typography sx={{ fontWeight: 600 }}>
                                 {handleActiveVotationState["nullVote"] || 0}
                              </Typography>
                           </Box>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: 600 }}>Votos faltantes: </Typography>
                              <Typography sx={{ fontWeight: 600 }}>
                                 {handleActiveVotationState["pending"] || 0}
                              </Typography>
                           </Box>
                        </Stack>
                     </Stack>
                  )
               ) : (
                  <VotationAnswerOptions votationAnswers={votationAnswers} setVotationAnswers={setVotationAnswers} />
               )}
               <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Button type="submit" variant="contained" sx={{ bgcolor: "#2D4357", color: "white", width: "33%" }}>
                     {state.vote || votationOnCourse ? "Terminar" : "Compartir"}
                  </Button>
                  <Button
                     type="button"
                     onClick={handleCancelVotation}
                     sx={{
                        bgcolor: "#E1E1E1",
                        border: 0.65,
                        borderColor: "#EFEFEF",
                        width: "33%",
                        color: "black",
                     }}
                  >
                     Cancelar
                  </Button>
               </Box>
            </Stack>
         </Form>
      </Formik>
   );
};
