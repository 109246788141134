import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBeneficiaryByFolderId, getFoldersAndFilesById } from "../../lib/usersBEClient";
import {
   Box,
   Typography,
   Stack,
   IconButton,
   Card,
   CircularProgress,
   Button,
   Menu,
   MenuItem,
   ListItemIcon,
} from "@mui/material";
import { MyFiles } from "../../components/Gobierno corporativo/MyFilesScreen/MyFiles";
import { Filters } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/Filters";
import { ArrowBack, FileUpload, Folder, FolderSharp, PictureAsPdf } from "@mui/icons-material";
import { formatFileSize, getDateAndTimeFromISODate } from "../../const/globalConst";
import { FilesContext } from "../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { UserContext } from "../../context/userContext";
import { getIconByType } from "../Gobierno corporativo/FilesVerificationScreen";
import { UploadFileBeneficiary } from "../../components/Beneficiary Controller/UploadFileBeneficiary";
import { CreateBeneficiaryFolderModal } from "../../components/Beneficiary Controller/CreateBeneficiaryFolderModal";

function yearNameVerification(str: string): boolean {
   const yearRegex = /^\d{4}$/;
   const year = parseInt(str, 10);
   return yearRegex.test(str) && year >= 1900 && year <= new Date().getFullYear();
}

const useGetFiles = () => {
   const { folderId } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [files, setFiles] = useState([]);
   const [reload, setReload] = useState(null);
   const { startDate, endDate, confirmModal, setDocumentSelected, setBeneficiary } = useContext(FilesContext);
   const { user, companySelected } = useContext(UserContext);

   useEffect(() => {
      const fetchFolders = async () => {
         if (!folderId || !companySelected) return;
         setIsLoading(true);
         try {
            const companyInGroup = user?.group?.companies.some((c) => c === companySelected._id);
            const response = await getFoldersAndFilesById(
               [folderId],
               companyInGroup ? user?.group?._id : companySelected._id
            );
            const beneficiary = await getBeneficiaryByFolderId(folderId);
            setBeneficiary(beneficiary);
            setDocumentSelected(response.folder);
            setFiles([...response.folder.children, ...response.files].sort((a, b) => a.name.localeCompare(b.name)));
         } catch (error) {
            console.error("Error fetching folders and files:", error);
         }
         setIsLoading(false);
      };
      if (folderId) fetchFolders();
   }, [folderId, reload, companySelected, setDocumentSelected]);

   const filteredData = useMemo(() => {
      return files
         .filter((file) => (file.company && file.company === companySelected._id) || file.name !== "Control")
         .map((iterator) => {
            if (iterator.size) {
               const name = iterator.name.split(".");
               name.pop();
               let nameNew = null;
               if (iterator.beneficiary) {
                  nameNew = (
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                        {getIconByType(iterator.beneficiary?.verified, iterator.beneficiary?.needUpdate, !iterator)}
                        <Typography variant="subtitle2">{iterator.name}</Typography>
                     </Box>
                  );
               } else {
                  nameNew = (
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                        <PictureAsPdf sx={{ color: "red" }} />
                        <Typography variant="subtitle2">{iterator.name}</Typography>
                     </Box>
                  );
               }
               return {
                  ...iterator,
                  name: nameNew,
                  originalName: name.join("."),
                  updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
                  owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
                  ownerId: iterator.owner ? iterator.owner._id : "",
                  size: formatFileSize(iterator.size),
                  originalType: iterator.name.split(".").pop(),
               };
            } else {
               return {
                  ...iterator,
                  type: "carpeta",
                  name:
                     iterator.name === "Control" ? (
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <Folder sx={{ color: "#162C44", fontSize: 25 }} />
                           <Typography variant="subtitle2">{iterator.name}</Typography>
                        </Box>
                     ) : (
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                           <Typography variant="subtitle2">{iterator.name}</Typography>
                        </Box>
                     ),
                  updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
                  owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
                  ownerId: iterator.owner ? iterator.owner._id : "",
                  size: "---",
                  originalName: iterator.name,
               };
            }
         });
   }, [files]);

   const processedData = useMemo(() => {
      if (confirmModal && startDate !== null && endDate !== null) {
         return filteredData.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
      }
      return filteredData;
   }, [filteredData, confirmModal, startDate, endDate]);

   return { isLoading, processedData, setReload, reload };
};

export const ExpedientScreen = () => {
   const { isLoading, processedData, setReload, reload } = useGetFiles();
   const { documentSelected, beneficiary } = useContext(FilesContext);
   const navigate = useNavigate();
   const buttonRef = useRef(null);
   const [anchorEl, setAnchorEl] = useState(null);
   const [menuWidth, setMenuWidth] = useState("auto");
   const [openUploadFile, setOpenUploadFile] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [name, setName] = useState("");
   const { folderId } = useParams();

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      if (buttonRef.current) setMenuWidth(buttonRef.current.offsetWidth);
   };
   const handleClose = () => setAnchorEl(null);

   return (
      <>
         <Card
            sx={{
               borderRadius: 2,
               display: "flex",
               alignItems: "center",
               py: 1,
               px: 2,
               my: 2,
            }}
         >
            <Box sx={{ flex: 1, display: "flex", alignItems: "center", columnGap: 1 }}>
               <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
               </IconButton>
               <Typography sx={{ flex: 1, alignItems: "center" }} variant="h5">
                  {typeof documentSelected?.originalName === "string" ? (
                     documentSelected.originalName
                  ) : typeof documentSelected?.name === "string" ? (
                     documentSelected.name
                  ) : (
                     <CircularProgress size={24} />
                  )}
               </Typography>
            </Box>
         </Card>

         <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
               <Filters />
            </Box>
            {folderId !== beneficiary?.folderId && (
               <>
                  <Button
                     variant="contained"
                     onClick={handleClick}
                     sx={{ width: "100%", maxWidth: "200px" }}
                     ref={buttonRef}
                  >
                     Nuevo
                  </Button>
                  {documentSelected && (
                     <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{ style: { width: menuWidth } }}
                     >
                        {yearNameVerification(documentSelected.name) ? (
                           <MenuItem
                              onClick={() => {
                                 setOpenUploadFile(true);
                                 handleClose();
                              }}
                           >
                              <ListItemIcon>
                                 <FileUpload />
                              </ListItemIcon>
                              Subir archivo
                           </MenuItem>
                        ) : (
                           <MenuItem
                              onClick={() => {
                                 setOpenModal(true);
                                 setName("");
                                 handleClose();
                              }}
                           >
                              <ListItemIcon>
                                 <FolderSharp />
                              </ListItemIcon>
                              Crear carpeta
                           </MenuItem>
                        )}
                     </Menu>
                  )}
               </>
            )}
         </Stack>
         <Box sx={{ py: 2 }}>
            <MyFiles files={processedData} isLoading={isLoading} setReload={setReload} reload={reload} beneficiary />
         </Box>
         <CreateBeneficiaryFolderModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            selected={folderId}
            value={name}
            setName={setName}
            reloadFiles={reload}
            setReloadFiles={setReload}
         />
         <UploadFileBeneficiary
            folder={folderId}
            setReload={setReload}
            reload={reload}
            openModalFile={openUploadFile}
            setOpenModalFile={setOpenUploadFile}
         />
      </>
   );
};
